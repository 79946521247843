import React, { useState, useEffect } from 'react';
import UpdateStatus from '../components/statusUpdate';

export default function UpdateStatusPage() {
 

  return (
    <div >
      <UpdateStatus/>
    </div>
  );
}
