import React from 'react';
import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/classNames';

function NewMemberNotification({ id, currentAdmin, newUser, groupName, clearNotification }) {
  return (
    <div className="relative flex items-start space-x-3 p-4 bg-white shadow-lg rounded-lg">
      <div className="flex-shrink-0 pt-0.5">
        <img
          alt="New Member"
          src="https://via.placeholder.com/40"
          className="h-10 w-10 rounded-full"
        />
      </div>
      <div className="min-w-0 flex-1">
        <div className="items-start gap-x-3">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            Hello {currentAdmin}.
          </p>
          <p className="text-xs leading-6 text-gray-500">
            {newUser} is now a member of your group, {groupName}.
          </p>
        </div>
        <p className="text-xs text-gray-400">{new Date().toLocaleString()}</p>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Menu as="div" className="relative flex-none">
          <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Menu.Items
            transition
            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none"
          >
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => clearNotification(id)}
                  className={classNames(
                    active ? 'bg-gray-50' : '',
                    'block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left'
                  )}
                >
                  Clear
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
}

export default NewMemberNotification;
