import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const MemberGroups = ({ linkedGroupData, handleHomePageButton }) => {
  const [error, setError] = useState('');

  useEffect(() => {
    if (!linkedGroupData || linkedGroupData.length === 0) {
      setError('No linked groups found or user not authenticated.');
    }
  }, [linkedGroupData]);

    // Function to get the image path based on group type
    const getImagePath = (groupType) => {
      switch (groupType) {
        case 'Business':
          return '/images/Business.svg';
        case 'Church':
          return '/images/Hospital.svg';
        case 'Extended Family':
          return '/images/Family.svg';
        case 'Friends':
          return '/images/Friend.svg';
        default:
          return '/images/Neighbor.svg';  // Default image if group type is not recognized
      }
    };

  return (
    <>
      {!linkedGroupData || linkedGroupData.length === 0 ? (
        <div className='bg-join'>
          <div className="text-center p-4 text-gray-800">
            <p className='h4'>Join a Circle</p>
            <p>
              Stay connected with groups or organizations you already know and trust by joining their status now circles to share preparedness tips and keep track of each other's status during emergencies.🤝
            </p>
            <button className="rounded-pill btn btn-success mt-4" onClick={handleHomePageButton}>
              Explore Familiar Circles
            </button>
          </div>
        </div>
      ) : (
        linkedGroupData.map((group) => (
          <Link to={`/Linked/${group.groupId}`} key={group.groupId}>
            <main className='border-top border-light'>
              <div className="relative flex justify-between gap-x-6 p-3 hover:bg-gray-50 sm:px-6 ">
                <div className="">
                  <img
                    className='shadow-sm'
                    src={getImagePath(group.groupType)}  // Set image path dynamically based on group type
                    alt={group.groupType}
                    style={{ width: '50px', height: '50px', borderRadius: '15px' }}
                  />
                </div>
                <div className="card-body ">
                  <h5 className="text-md font-medium leading-6 text-gray-900 ">{group.groupName}</h5>
                  <p className="card-text text-sm text-muted">
                    <span className='fw-semibold'>Admin:</span> {group.ownerName}
                  </p>
                </div>
                <ChevronRightIcon className="mt-2 h-7 w-7 text-gray-400" aria-hidden="true" />
              </div>
            </main>
          </Link>
        ))
      )}
      {/* {error && <div className="text-danger text-center mt-3">{error}</div>} */}
    </>
  );
};

export default MemberGroups;
