import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import { requestForToken } from './firebase';
import { createUser as createUserAPI, fetchUserByEmail, updateUser } from './Api/apiService';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();  // Use React Router's navigate for redirection

  // Helper function to fetch or create a user profile
  const getOrCreateUserProfile = async (email, additionalData = {}) => {
    const originalEmail = email;
    const normalizedEmail = originalEmail.toLowerCase();

    let userProfile = await fetchUserByEmail(originalEmail); // Use the original email case
    if (!userProfile) {
      // If user profile does not exist, create a new one with normalized email
      userProfile = await createUserAPI({ ...additionalData, userEmail: normalizedEmail });
    }
    return userProfile;
  };

  const authHandler = async (user, additionalData = {}, isNewUser = false) => {
    const originalEmail = user.email; // Use the original email case for backend requests
    const normalizedEmail = originalEmail.toLowerCase(); // Normalize the email for storage
    try {
      console.log('Auth handler triggered for user:', originalEmail);
      console.log('Additional data:', additionalData);

      let userProfile;

      if (isNewUser) {
        // Ensure all required fields are present for new users
        if (!additionalData.userEmail || !additionalData.userFirstName || !additionalData.userLastName) {
          throw new Error('Missing required user data fields for new user.');
        }
        userProfile = await createUserAPI({ ...additionalData, userEmail: normalizedEmail });
      } else {
        // Fetch or create user profile using the original email case
        userProfile = await getOrCreateUserProfile(originalEmail, additionalData);
        if (!userProfile) {
          throw new Error('User profile could not be fetched or created.');
        }

        // Supplement additionalData with data from the user profile if fields are missing
        additionalData.userFirstName = additionalData.userFirstName || userProfile.userFirstName;
        additionalData.userLastName = additionalData.userLastName || userProfile.userLastName;
        additionalData.userEmail = additionalData.userEmail || userProfile.userEmail;
      }

      const currentToken = await requestForToken();
      if (currentToken) {
        console.log('FCM token received:', currentToken);
        await updateUser(userProfile.id, { ...userProfile, fcmtoken: currentToken });
      }

      setCurrentUser(user);
    
      // Store user data with normalized email in localStorage for consistency
      localStorage.setItem(`userData-${normalizedEmail}`, JSON.stringify(userProfile));
      // navigate('/home'); // Redirect to the home after login
     
    } catch (error) {
      console.error('Error handling auth:', error);
    }
  };

  const login = async (email, password) => {
    const normalizedEmail = email.toLowerCase(); // Normalize email for consistency
    try {
      const { user } = await signInWithEmailAndPassword(auth, normalizedEmail, password);
      await authHandler(user, { userEmail: normalizedEmail });
      navigate('/home'); // Ensure navigation to home after state is set

    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  };

  const createUserMeth = async (email, password, additionalData = {}) => {
    const normalizedEmail = email.toLowerCase(); // Normalize email for consistency
    try {
      const { user } = await createUserWithEmailAndPassword(auth, normalizedEmail, password);
      await authHandler(user, { ...additionalData, userEmail: normalizedEmail }, true); // Indicate new user creation
      return user;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('Email already in use');
      } else {
        throw error;
      }
    }
  };

  const logout = async () => {
    const normalizedEmail = currentUser.email.toLowerCase(); // Normalize email for consistency
    try {
      const response = await fetchUserByEmail(currentUser.email); // Use original email case
      if (response) {
        await updateUser(response.id, { ...response, fcmtoken: null });
      }
      await signOut(auth);
      setCurrentUser(null);
      localStorage.removeItem(`userData-${normalizedEmail}`);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  };

  const signInWithProvider = async (provider) => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      const originalEmail = user.email;
      const normalizedEmail = originalEmail.toLowerCase();

      const additionalData = {
        userFirstName: user.displayName?.split(' ')[0] || '',
        userLastName: user.displayName?.split(' ')[1] || '',
        userEmail: normalizedEmail,
        userStatus: 'NO RESPONSE',
        statusColor: 'Gray',
        profileImageURL: user.photoURL || '',
        fcmtoken: '',
        managedGroupIDs: [],
        joinedGroupIDs: [],
        groupAlert: 'Inactive',
        subscription: 'Basic',
        subscriptionPackage: 'Monthly',
        dateSubscribed: new Date().toISOString(),
        phone: '',
        title: '',
        address: ''
      };

      const userProfile = await getOrCreateUserProfile(originalEmail, additionalData);
      await authHandler(user, additionalData, !userProfile); // Treat as new user if profile was created

      return user;
    } catch (error) {
      console.error('Error signing in with provider:', error);
      throw error;
    }
  };

  const signInWithGoogle = () => signInWithProvider(new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' }));
  const signInWithFacebook = () => signInWithProvider(new FacebookAuthProvider());

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) await authHandler(user, { userEmail: user.email });
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  const value = {
    currentUser,
    login,
    logout,
    createUserMeth,
    signInWithGoogle,
    signInWithFacebook,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
