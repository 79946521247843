/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

const Dashboard = () => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState({ not2: 'Not Started', prog2: 'In Progress', comp2: 'Completed' });

  useEffect(() => {
    applyName();
    cardUpdates();
  }, []);

  const applyName = () => {
    const prfName = JSON.parse(localStorage.getItem('prfName'));
    if (prfName) {
      setName(prfName[0].myName || '');
    } else {
      $('#getName').modal('show');
    }
  };

  const updateName = () => {
    const newName = document.getElementById('newName').value;
    localStorage.setItem('prfName', JSON.stringify([{ myName: newName }]));
    setName(newName);
    window.location.reload();
  };

  const getStarted = () => {
    localStorage.setItem('prfName', JSON.stringify([{ gotStarted: 'Started' }]));
    window.location.reload();
  };

  const cardUpdates = () => {
    const viewPlan = JSON.parse(localStorage.getItem("addTo72"));
    const updatedPlan = JSON.parse(localStorage.getItem("editedPlan"));
    const foodList = JSON.parse(localStorage.getItem("foodList"));
    const addList = JSON.parse(localStorage.getItem("addList"));
    const emerCont = JSON.parse(localStorage.getItem("emerCont"));

    if (viewPlan) {
      document.getElementById("continue1").style.display = "block";
      document.getElementById("add1").style.display = "none";
      document.getElementById("not1").style.display = "none";
      document.getElementById("prog1").style.display = "block";
      const evacForm = document.getElementsByClassName("evacForm");
      for (let i = 0; i < evacForm.length; i++) {
        evacForm[i].classList.add("d-block");
      }
      const completed = document.getElementsByClassName("showComplete");
      for (let i = 0; i < completed.length; i++) {
        completed[i].classList.add("d-block");
      }
    }

    if (updatedPlan) {
      localStorage.removeItem('addTo72');
      const step1M = document.getElementsByClassName("step1M");
      for (let i = 0; i < step1M.length; i++) {
        step1M[i].classList.add("d-none");
      }
      const step1C = document.getElementsByClassName("step1C");
      for (let i = 0; i < step1C.length; i++) {
        step1C[i].classList.remove("d-none");
      }
      document.getElementById("continue1").style.display = "none";
      document.getElementById("view1").style.display = "block";
      document.getElementById("add1").style.display = "none";
      document.getElementById("not1").style.display = "none";
      document.getElementById("prog1").style.display = "none";
      document.getElementById("comp1").style.display = "block";
      document.getElementById("step1C").style.display = "block";
    }

    if (emerCont) {
      const step1_5M = document.getElementsByClassName("step1_5M");
      for (let i = 0; i < step1_5M.length; i++) {
        step1_5M[i].classList.add("d-none");
      }
      const step1_5C = document.getElementsByClassName("step1_5C");
      for (let i = 0; i < step1_5C.length; i++) {
        step1_5C[i].classList.remove("d-none");
      }
      document.getElementById("continue1_5").style.display = "none";
      document.getElementById("view1_5").style.display = "block";
      document.getElementById("add1_5").style.display = "none";
      document.getElementById("not1_5").style.display = "none";
      document.getElementById("prog1_5").style.display = "none";
      document.getElementById("comp1_5").style.display = "block";
    }

    if (foodList) {
      const step2M = document.getElementsByClassName("step2M");
      for (let i = 0; i < step2M.length; i++) {
        step2M[i].classList.add("d-none");
      }
      const step2C = document.getElementsByClassName("step2C");
      for (let i = 0; i < step2C.length; i++) {
        step2C[i].classList.remove("d-none");
      }
      document.getElementById("continue2").style.display = "none";
      document.getElementById("view2").style.display = "block";
      document.getElementById("add2").style.display = "none";
      document.getElementById("not2").style.display = "none";
      document.getElementById("prog2").style.display = "none";
      document.getElementById("comp2").style.display = "block";
      document.getElementById("step2C").style.display = "block";
    }

    if (addList) {
      const step3M = document.getElementsByClassName("step3M");
      for (let i = 0; i < step3M.length; i++) {
        step3M[i].classList.add("d-none");
      }
      const step3C = document.getElementsByClassName("step3C");
      for (let i = 0; i < step3C.length; i++) {
        step3C[i].classList.remove("d-none");
      }
      document.getElementById("continue3").style.display = "none";
      document.getElementById("view3").style.display = "block";
      document.getElementById("add3").style.display = "none";
      document.getElementById("not3").style.display = "none";
      document.getElementById("prog3").style.display = "none";
      document.getElementById("comp3").style.display = "block";
      document.getElementById("step3C").style.display = "block";
    }

    if (addList || foodList || updatedPlan || emerCont) {
      const dontShow = document.getElementsByClassName("dashBlock");
      for (let i = 0; i < dontShow.length; i++) {
        dontShow[i].classList.add("d-none");
      }
      const continueDash = document.getElementsByClassName("continueDash");
      for (let i = 0; i < continueDash.length; i++) {
        continueDash[i].classList.remove("d-none");
        continueDash[i].classList.add("d-block");
      }
      if (addList && foodList && updatedPlan) {
        const dontShow = document.getElementsByClassName("dashBlock");
        for (let i = 0; i < dontShow.length; i++) {
          dontShow[i].classList.add("d-none");
        }
        const continueDash = document.getElementsByClassName("continueDash");
        for (let i = 0; i < continueDash.length; i++) {
          continueDash[i].classList.remove("d-block");
          continueDash[i].classList.add("d-none");
        }
        const completeDash = document.getElementsByClassName("completeDash");
        for (let i = 0; i < completeDash.length; i++) {
          completeDash[i].classList.remove("d-none");
          completeDash[i].classList.add("d-block");
        }
      }
    }
  };

  return (
    <main className="container-fluid mt-5 txtBlu">
      <div className="d-flex justify-content-between align-items-center my-4 mt-5">
        <div className="d-flex align-items-center">
          <h2 className="txtImpact ms-4 font-weight-bold txtGrn text-3xl">EMERGENCY PLAN</h2>
        </div>
        <div className="d-flex justify-content-end">
          <button type="button" className="rounded-pill btn-sm btn btn-primary font-weight-bold" data-toggle="modal" data-target="#exampleModalCenter">
            Share<img className="ml-2 mb-1" height="25px" src="./images-sitprep/icons8-share-50.png" alt="Share" />
          </button>
        </div>
      </div>
      <div className="container">
        <div className="shadow rcorners bg-white mt-5">
          <p className="text-center font-weight-bolder display-6 pt-3 txtImpact txtBlu dashBlock">Welcome<span className="" id="displayname">{name}</span>!</p>
          <p className="text-center font-weight-bolder display-6 pt-3 txtImpact txtBlu continueDash d-none">Good job<span className="" id="displayname">{name}</span>!</p>
          <p className="text-center font-weight-bolder display-6 pt-3 txtImpact txtBlu d-none completeDash">Congratulations<span className="" id="displayname">{name}</span>!</p>
          <p className="text-center font-weight-bolder h2 robotoFnt text-warning dashBlock px-2">Let's begin by creating your emergency plan.</p>
          <p className="text-center font-weight-bolder h2 robotoFnt txtBlu continueDash d-none">Keep up the good work.</p>
          <p className="text-center font-weight-bolder h2 robotoFnt txtBlu completeDash d-none pb-2 px-2">Well done on completing your plan!</p>
          <p className="text-center font-weight-light h4 robotoFnt dashBlock px-2">Choose any step below that you're ready to complete.</p>
          <p className="text-center font-weight-light h4 robotoFnt continueDash d-none px-2">Now, select any step below that you're ready to tackle next.</p>
          <p className="text-center font-weight-light h4 robotoFnt completeDash d-none px-4">Remember to refine your 72hr plan as circumstances and family dynamics evolve. Stay safe and prepared with SitPrep.</p>
          <section className="row text-center">
            <main className="col-lg-3">
              <a className="btn d-flex justify-content-left align-items-center step2M" href="./Food Supply.html">
                <div className="d-flex py-3" id="step2M">
                  <div className="shadow-sm bg-white circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-outlined text-success">kitchen</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pt-1 pl-3 font-weight-bold h5">72HR FOOD MENU</p>
                  </div>
                </div>
              </a>
              <a className="d-none btn d-flex justify-content-left align-items-center step2C" href="./Food Supply.html">
                <div className="d-flex py-3" id="step2C">
                  <div className="shadow-sm recBlu circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-rounded text-light">done</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center bg">
                    <p className="pt-1 pl-3 font-weight-bold h5 text-success">72Hr Food Menu</p>
                  </div>
                </div>
              </a>
            </main>
            <main className="col-lg-3">
              <a className="btn d-flex justify-content-left align-items-center text-left step1M" href="./create_evac_plan.html">
                <div className="d-flex py-3">
                  <div className="shadow-sm bg-white circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-outlined text-success">assignment</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pt-1 pl-3 font-weight-bold h5 txtBlu">Evacuation Plan</p>
                  </div>
                </div>
              </a>
              <a className="d-none btn d-flex justify-content-left align-items-center step1C" href="./create_evac_plan.html" id="step1C">
                <div className="d-flex py-3">
                  <div className="shadow-sm recBlu circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-rounded text-light">done</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pt-1 pl-3 font-weight-bold h5 text-success">Evacuation Plan</p>
                  </div>
                </div>
              </a>
            </main>
            <main className="col-lg-3">
              <a className="btn d-flex justify-content-left align-items-center text-left step1_5M" href="./emergency_contacts.html">
                <div className="d-flex py-3">
                  <div className="shadow-sm bg-white circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-outlined text-success">
                      <span className="material-icons">call</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pt-1 pl-3 font-weight-bold h5 txtBlu">Emergency Contacts</p>
                  </div>
                </div>
              </a>
              <a className="d-none btn d-flex justify-content-left align-items-center step1_5C" href="./emergency_contacts.html" id="step1_5C">
                <div className="d-flex py-3">
                  <div className="shadow-sm recBlu circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-rounded text-light">done</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pt-1 pl-3 font-weight-bold h5 text-success">Emergency Contacts</p>
                  </div>
                </div>
              </a>
            </main>
            <main className="col-lg-3">
              <a className="btn d-flex justify-content-left align-items-center step3M" href="./Additional_Supplies.html" id="step3M">
                <div className="d-flex py-3">
                  <div className="shadow-sm bg-white circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-rounded text-success">backpack</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pt-1 pl-3 font-weight-bold h5 txtBlu">Additional Supplies</p>
                  </div>
                </div>
              </a>
              <a className="d-none btn d-flex justify-content-left align-items-center step3C" href="./Additional_Supplies.html">
                <div className="d-flex py-3" id="step3C">
                  <div className="shadow-sm recBlu circle circleBdr justify-content-center align-items-center d-flex">
                    <span className="material-symbols-rounded text-light">done</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center bg">
                    <p className="pt-1 pl-3 font-weight-bold h5 text-success">Additional Supplies</p>
                  </div>
                </div>
              </a>
            </main>
          </section>
        </div>
        <p className="text-muted my-3 text-center"><small><span className="font-weight-bold">Disclosure: </span>SitPrep is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. As an Amazon Associate, we may earn a small commission from qualifying purchases. This comes at no additional cost to you and helps us continue to provide informative content. Thank you for your support!</small></p>
        <section className="py-5">
          <div className="py-5">
            <div className="">
              <div className="shadow rcorners bg-white stp2-pic p-4">
                <div className="card-body">
                  <main className="d-flex">
                    <div className="col-lg-5 phone-block pt-5">
                      <img src="./images-sitprep/Food Supply Main – 1.png" width="65%" alt="Food Supply" />
                    </div>
                    <div className="col-lg-7 robotoFnt">
                      <div className="d-flex card-title">
                        <p className="h3 font-weight-bold text-left txtBlu robotoFnt">Craft Your 72-Hour Emergency Food Menu</p>
                      </div>
                      <br />
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 phone-block robotoFnt txtBlu">Be guided in crafting a 72hr food storage</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu">Keep track of food supply needs</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu phone-block">Share list with families and friends</p>
                      </div>
                      <div className="row py-4">
                        <div className="col-lg-6 ps-5">
                          <a id="add2" type="button" className="btn btn-lg btn-primary rounded-pill text-center" href="./Food Supply.html">Craft My Menu</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-primary rounded-pill text-center" href="./Food Supply.html" id="continue2">Continue Planning</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-success rounded-pill text-center" href="./download_foodsupply.html" id="view2">View My Menu</a>
                        </div>
                      </div>
                      <div className="phone-block pl-5 float-right">
                        <p className="font-weight-bold font-weight-light h5">Status:
                          <span className="font-weight-bold txtLiteOrg pl-1 float-right" id="not2"> (Not Started)</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-warning float-right" id="prog2"> In Progess</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-success float-right" id="comp2"> Completed</span>
                        </p>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="">
              <div className="shadow rcorners bg-white stp1-pic p-4">
                <div className="card-body">
                  <main className="d-flex">
                    <div className="col-lg-5 phone-block pt-5">
                      <img src="./images-sitprep/Gathering Main.png" width="65%" alt="Evacuation Plan" />
                    </div>
                    <div className="col-lg-7 robotoFnt">
                      <div className="d-flex card-title">
                        <p className="h3 font-weight-bold text-left txtBlu robotoFnt">Create Evacuation Plan</p>
                      </div>
                      <br />
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 phone-block robotoFnt txtBlu">Be guided in creating an evacuation plan</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu">Decide on a safe place for your household to reunite</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu phone-block">Share with families and friends</p>
                      </div>
                      <div className="row py-4">
                        <div className="col-lg-6 ps-5">
                          <button id="add1" type="button" className="btn btn-lg btn-primary rounded-pill text-center" onClick={updateName}>Create Plan</button>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-primary rounded-pill text-center" href="./create_evac_plan.html" id="continue1">Continue Planning</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-success rounded-pill text-center" href="./create_evac_plan.html" id="view1">Create Another Plan</a>
                        </div>
                      </div>
                      <div className="phone-block pl-5 float-right">
                        <p className="font-weight-bold font-weight-light h5">Status:
                          <span className="font-weight-bold txtLiteOrg pl-1 float-right" id="not1">(Not Started)</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-warning float-right" id="prog1">(In Progess)</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-success float-right" id="comp1">Completed</span>
                        </p>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="">
              <div className="shadow rcorners bg-white stp1_5-pic p-4">
                <div className="card-body">
                  <main className="d-flex">
                    <div className="col-lg-5 phone-block pt-5">
                      <img src="./images-sitprep/Contact.png" width="65%" alt="Emergency Contacts" />
                    </div>
                    <div className="col-lg-7 robotoFnt">
                      <div className="d-flex card-title">
                        <p className="h3 font-weight-bold text-left txtBlu robotoFnt">Designate Emergency Contacts</p>
                      </div>
                      <br />
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 phone-block robotoFnt txtBlu">Be guided in choosing designated contacts</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu">Assign who individuals will contact during an emergency</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu phone-block">Download and share list with family and friends</p>
                      </div>
                      <div className="row py-4">
                        <div className="col-lg-6 ps-5">
                          <a id="add1_5" type="button" className="btn btn-lg btn-primary rounded-pill text-center" href="./emergency_contacts.html">Designate Contacts</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-primary rounded-pill text-center" href="./emergency_contacts.html" id="continue1_5">Continue Planning</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-success rounded-pill text-center" href="./emergency_contacts.html" id="view1_5">View My Plan</a>
                        </div>
                      </div>
                      <div className="phone-block pl-5 float-right">
                        <p className="font-weight-bold font-weight-light h5">Status:
                          <span className="font-weight-bold txtLiteOrg pl-1 float-right" id="not1_5"> (Not Started)</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-warning float-right" id="prog1_5"> In Progess</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-success float-right" id="comp1_5"> Completed</span>
                        </p>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="">
              <div className="shadow rcorners bg-white stp3-pic p-4">
                <div className="card-body">
                  <main className="d-flex">
                    <div className="col-lg-5 phone-block pt-5">
                      <img src="./images-sitprep/Additional Supplies Main.png" width="65%" alt="Additional Supplies" />
                    </div>
                    <div className="col-lg-7 robotoFnt">
                      <div className="d-flex card-title">
                        <p className="h3 font-weight-bold text-left txtBlu robotoFnt">Add Additional Supplies</p>
                      </div>
                      <br />
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu">Keep track of additional supply needs</p>
                      </div>
                      <div className="d-flex py-1">
                        <p className="font-weight-normal h5 robotoFnt txtBlu phone-block">Share list with families and friends</p>
                      </div>
                      <div className="row py-4">
                        <div className="col-lg-6 ps-5">
                          <a id="add3" type="button" className="btn btn-lg btn-primary rounded-pill text-center" href="./Additional_Supplies.html">Start Adding Supplies</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-primary rounded-pill text-center" href="./Additional_Supplies.html" id="continue3">Continue Planning</a>
                          <a style={{ display: 'none' }} className="btn btn-lg btn-success rounded-pill text-center" href="./Additional_Supplies.html" id="view3">View Supply List</a>
                        </div>
                      </div>
                      <div className="phone-block pl-5 float-right">
                        <p className="font-weight-bold font-weight-light h5">Status:
                          <span className="font-weight-bold txtLiteOrg pl-1 float-right" id="not3">(Not Started)</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-warning float-right" id="prog3">In Progess</span>
                          <span style={{ display: 'none' }} className="pl-1 font-weight-bold text-success float-right" id="comp3">Completed</span>
                        </p>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <canvas className="my-4 w-100" id="myChart" width="900" height="380"></canvas>
      <div className="fab-container">
        <a className="rounded-pill btn btn-lg btn-warning shadow text-dark pb-3 saiFabGA" href="./sai.html"><img className="img-fluid" src="./images-sitprep/SAI-FAB.png" width="75px" alt="SAI" /></a>
      </div>
      <div className="modal fade getName" id="getName" tabIndex="-1" role="dialog" aria-labelledby="getName" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="text-center mt-5">
              <img src="./images-sitprep/Share 1.png" width="75px" alt="Welcome" />
            </div>
            <p className="text-center title">Welcome to SitPrep!</p>
            <p className="h3 font-weight-bold txtGrn robotoFnt text-center mx-5">Start Your SitPrep Journey</p>
            <div className="container text-break text-wrap">
              <div className="mt-2 h5 font-weight-normal txtBlu robotoFnt text-center mx-5">Get ready to explore! Check out the different sections you'd like to complete today and know that our website is designed to be easy and straightforward to use.</div>
            </div>
            <div className="mb-5 text-center">
              <button type="button" className="btn btn-success saveName" onClick={getStarted} data-dismiss="modal" data-toggle="modal" data-target="#getName">Get Started</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade getName" id="updateName" tabIndex="-1" role="dialog" aria-labelledby="updateName" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="d-flex my-3 modal-body justify-content-center text-center">
              <input className="w-50 form-control text-center" id="newName" name="inputName" type="text" placeholder="Enter Preferred Name" />
            </div>
            <div className="mb-5 float-right text-center">
              <button type="button" className="noOutline btn-lg btn-dark" onClick={updateName} data-dismiss="modal" data-toggle="modal" data-target="#updateName">Save Name</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
