// UserAvatar.js
import React from 'react';

const MainAvatar = ({ userProfile }) => {
  // Function to assign dynamic background color based on initials
  const getBackgroundColor = (user) => {
    const backgroundColors = ['#000000', '#a9aeb2'];
    if (!user?.userFirstName || !user?.userLastName) {
      return '#000000'; // Default to black if no name is available
    }
    const initial = user.userFirstName.charAt(0).toUpperCase();
    const colorIndex = initial.charCodeAt(0) % backgroundColors.length;
    return backgroundColors[colorIndex];
  };

  const getTextColor = (bgColor) => {
    // For lighter backgrounds, use dark text; for darker backgrounds, use white
    const lightColors = ['#adb5bd', '#f8f9fa']; // Add any other light colors here
    return lightColors.includes(bgColor) ? '#000000' : '#ffffff';
  };

  return (
    <button
      className="rounded-full flex items-center justify-center me-4"
      onClick={() => alert('Avatar clicked!')}
      style={{
        width: '40px',
        height: '40px',
      }}
    >
      {userProfile?.profileImageURL ? (
        <img
          src={userProfile.profileImageURL}
          alt="User Avatar"
          className="rounded-full"
          style={{
            width: '40px',
            height: '40px',
            objectFit: 'cover',
          }}
        />
      ) : (
        <span
          className="rounded-full flex items-center justify-center"
          style={{
            width: '40px',
            height: '40px',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: getBackgroundColor(userProfile),
            color: getTextColor(getBackgroundColor(userProfile)),
          }}
        >
          {`${userProfile?.userFirstName?.charAt(0) || ''}${userProfile?.userLastName?.charAt(0) || ''}`}
        </span>
      )}
    </button>
  );
};

export default MainAvatar;
