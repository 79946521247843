import React, { useEffect, useState, useRef } from 'react';
import { IoMdMore } from 'react-icons/io';
import { fetchAllUserInfo } from '../Api/apiService';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaSms } from 'react-icons/fa';

const IndividualUser = ({ userList }) => {
  const [userListData, setUserListData] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const usersData = allUserInfo.filter(user => userList.includes(user.userEmail));
        setUserListData(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [userList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  // Function to assign dynamic background color based on initials
  const getBackgroundColor = (user) => {
    const backgroundColors = ['#000000', '#a9aeb2'];
    if (!user.userFirstName || !user.userLastName) {
      return '#000000'; // Default to black if no name is available
    }
    const initial = user.userFirstName.charAt(0).toUpperCase();
    const colorIndex = initial.charCodeAt(0) % backgroundColors.length;
    return backgroundColors[colorIndex];
  };
  
  const getTextColor = (bgColor) => {
    // For lighter backgrounds, use dark text; for darker backgrounds, use white
    const lightColors = ['#adb5bd', '#f8f9fa']; // Add any other light colors here
    return lightColors.includes(bgColor) ? '#000000' : '#ffffff';
  };
  
  

  return (
    <div>
      {userListData.length > 0 ? (
        userListData.map((user) => (
          <div key={user.userEmail} className="container-fluid pb-4">
            <div className="rounded-users pe-3">
              <div className="flex">
                <div className="ml-2 mr-4">
                <button
                      className="rounded-full flex items-center justify-center shadow-sm"
                      onClick={() => alert('Working for now')}
                    >
                      {user.profileImageURL ? (
                        <img
                          src={user.profileImageURL}
                          alt="User Avatar"
                          className="rounded-full"
                          style={{
                            width: '45px',
                            height: '45px',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <span
                          className="rounded-full flex items-center justify-center"
                          style={{
                            width: '45px',
                            height: '45px',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            backgroundColor: getBackgroundColor(user),
                            color: getTextColor(getBackgroundColor(user)), // Set text color dynamically
                          }}
                        >
                          {`${user.userFirstName?.charAt(0) || ''}${user.userLastName?.charAt(0) || ''}`}
                        </span>
                      )}
                    </button>

                </div>
                <div className="flex-grow ml-3">
                  <div className="font-medium text-gray-700">{`${user.userFirstName} ${user.userLastName}`}</div>
                  {/* Display user status with badges */}
                  {user.userStatus === 'INJURED' && (
                    <span className="inline-flex items-center rounded-full custom-bg-red px-1.5 py-0.5 text-xs font-medium text-red-700">
                      INJURED
                    </span>
                  )}
                  
                  {user.userStatus === 'HELP' && (
                    <span className="inline-flex items-center rounded-full custom-bg-yellow px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                      HELP
                    </span>
                  )}

                  {user.userStatus === 'NO RESPONSE' && (
                    <span className="badge rounded-pill custom-bg-purple px-1.5 py-0.5  text-xs font-medium text-purple-700">
                      NO RESPONSE
                    </span>
                  )}

                  {user.userStatus === 'SAFE' && (
                    <span className="inline-flex items-center rounded-full custom-bg-green px-1.5 py-0.5 text-xs font-medium text-green-700">
                      SAFE
                    </span>
                  )}

                  {(!user.userStatus || user.userStatus === 'UNKNOWN') && (
                    <span className="inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                      Status not available
                    </span>
                  )}

                  {/* Display group roles */}
                  {user.groups && user.groups.map((group) => (
                    <div key={group.groupID}>
                      {group.memberRole === 'owner' && <span className="badge bg-success">Owner</span>}
                      {group.memberRole === 'admin' && <span className="badge bg-primary">Admin</span>}
                      {group.memberRole === 'member' && <span className="badge bg-secondary">Member</span>}
                      <span className="text-primary">{group.groupName}</span>
                    </div>
                  ))}
                </div>
                <div className="ml-auto relative">
                  <button
                    onClick={() => setShowOptions(user.userEmail === showOptions ? null : user.userEmail)}
                    className="text-2xl text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    <IoMdMore />
                  </button>
                  {user.userEmail === showOptions && (
                    <div
                      ref={optionsRef}
                      className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10"
                    >
                      {user.phone && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`tel:${user.phone}`)}
                        >
                          <FaPhoneAlt className="inline mr-2" /> Call {user.userFirstName}
                        </button>
                      )}
                      {user.phone && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`sms:${user.phone}`)}
                        >
                          <FaSms className="inline mr-2" /> Text {user.userFirstName}
                        </button>
                      )}
                      {user.userEmail && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`mailto:${user.userEmail}`)}
                        >
                          <FaEnvelope className="inline mr-2" /> Email {user.userFirstName}
                        </button>
                      )}
                      {user.address && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(user.address)}`)}
                        >
                          <FaMapMarkerAlt className="inline mr-2" /> Get Directions {user.userFirstName}
                        </button>
                      )}
                      {!user.phone && !user.userEmail && !user.address && (
                        <div className="text-gray-600 p-4">No contact information available</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default IndividualUser;
