import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { requestForToken } from '../firebase';
import { fetchAllGroups, fetchAllUserInfo, updateUser, updateGroup, fetchUserByEmail } from '../Api/apiService';
import InstallPrompt from './InstallPrompt ';

const AllGroups = ({ currentUserEmail, deferredPrompt, initialSearchQuery = '' }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // Add state for success feedback
  const [isInstallPromptOpen, setIsInstallPromptOpen] = useState(false);
  const [requestedGroups, setRequestedGroups] = useState([]); // Track requested groups for dynamic button update

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const currentUser = allUserInfo.find((user) => user.userEmail === currentUserEmail);

        if (currentUser) {
          const groupsData = await fetchAllGroups();
          setAllGroups(groupsData
            .filter((group) => !group.memberEmails.includes(currentUserEmail))
            .sort((a, b) => a.groupName.localeCompare(b.groupName))
          );
        } else {
          setError('User document not found');
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
        setError('Error fetching groups. Please try again later.');
      }
    };
    fetchGroups();
  }, [currentUserEmail]);

   // Clear the success message after a few seconds
   useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer); // Clear timeout if the component unmounts or successMessage changes
    }
  }, [successMessage]);

  const handleJoinGroup = async (groupId) => {
    try {
      const currentToken = await requestForToken();
      const currentUser = await fetchUserByEmail(currentUserEmail);

      if (currentUser) {
        if (currentToken) {
          await updateUser(currentUser.id, { ...currentUser, fcmtoken: currentToken });
        }

        const groupData = await fetchAllGroups().then((groupsData) => groupsData.find((group) => group.groupId === groupId));
        if (groupData && groupData.privacy !== 'Private') {
          // For public groups, add the user directly to memberEmails
          const updatedGroup = {
            ...groupData,
            memberEmails: [...new Set([...groupData.memberEmails, currentUserEmail])]
          };

          await updateGroup(groupId, updatedGroup);

          // Update the user's joined groups
          const updatedUser = {
            ...currentUser,
            joinedGroupIDs: [...new Set([...currentUser.joinedGroupIDs, groupId])]
          };
          await updateUser(currentUser.id, updatedUser);
          
          // Remove the group from the displayed list after joining
          setAllGroups((prevGroups) => prevGroups.filter((group) => group.groupId !== groupId));

          // Prompt the install if not running as standalone
          const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
          if (!isStandalone) {
            setIsInstallPromptOpen(true);
          }
        } else {
          setError('Group not found or is private');
        }
      } else {
        setError('User not found');
      }
    } catch (error) {
      console.error('Error joining group:', error);
      setError('An error occurred while joining the group. Please try again later.');
    }
    setSuccessMessage('You have successfully joined the group.');
  };

  const handleRequestToJoin = async (groupId) => {
    try {
      const currentUser = await fetchUserByEmail(currentUserEmail);

      if (currentUser) {
        const groupData = await fetchAllGroups().then((groupsData) => groupsData.find((group) => group.groupId === groupId));
        if (groupData && groupData.privacy === 'Private') {
          // For private groups, add the user to pendingMemberEmails
          const updatedGroup = {
            ...groupData,
            pendingMemberEmails: [...new Set([...groupData.pendingMemberEmails || [], currentUserEmail])]
          };

          await updateGroup(groupId, updatedGroup);

          // Add group to requested groups state to disable the button dynamically
          setRequestedGroups((prevRequestedGroups) => [...prevRequestedGroups, groupId]);

          // Show success feedback
          setSuccessMessage('Your request to join the group has been sent.');
        } else {
          setError('Group not found or is not private');
        }
      } else {
        setError('User not found');
      }
    } catch (error) {
      console.error('Error requesting to join group:', error);
      setError('An error occurred while requesting to join the group. Please try again later.');
    }
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const filteredGroups = allGroups.filter((group) => {
    const query = searchQuery.toLowerCase();
    return (
      (group.groupName && group.groupName.toLowerCase().includes(query)) ||
      (group.ownerName && group.ownerName.toLowerCase().includes(query)) ||
      (group.description && group.description.toLowerCase().includes(query)) ||
      (group.ownerEmail && group.ownerEmail.toLowerCase().includes(query)) ||
      (group.groupId && group.groupId.toString().toLowerCase().includes(query)) ||
      (group.zipcode && group.zipcode.toLowerCase().includes(query))
    );
  });

   // Function to get the image path based on group type
   const getImagePath = (groupType) => {
    switch (groupType) {
      case 'Business':
        return '/images/Business.svg';
      case 'Church':
        return '/images/Hospital.svg';
      case 'Extended Family':
        return '/images/Family.svg';
      case 'Friends':
        return '/images/Friend.svg';
      default:
        return '/images/Neighbor.svg';  // Default image if group type is not recognized
    }
  };

  return (
    <div>
      <div className="pt-3">
        <div className="flex text-white mb-5 m-3 bg-white rounded ring-1 ring-gray-900/5">
          <div className="ms-3 text-slate-400 h2 mt-2">
            <IoSearch />
          </div>
          <input
            type="text"
            className="mt-1 text-slate-600 placeholder:italic placeholder:text-slate-400 form-control bg-white rounded border border-0 focus:outline-none focus:shadow-none"
            id="search"
            placeholder="Search group name, owner, group id, or zip"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {error && <p className="text-danger">{error}</p>}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredGroups.map((group) => (
          <div className="mx-2 mb-2 ring-1 ring-gray-900/5 p-3 rounded-card bg-white" key={group.groupId}>
            <div className="d-flex">
              <div>
                <img
                className='shadow-sm'
                src={getImagePath(group.groupType)}  // Set image path dynamically based on group type
                alt={group.groupType}
                  style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius: '20px' }}
                />
              </div>
              <div className="card-body ps-3">
                <p className="text-lg fw-semibold card-title pt-2">{group.groupName}</p>
                <p className="text-gray-600 text-sm">
                  <span className="fw-bold card-text"> Leader: </span> {group.ownerName}
                </p>
                <p className="text-gray-600 text-sm">
                  <span className="fw-bold card-text"> Privacy: </span> {group.privacy}
                </p>
              </div>
            </div>
            <div key={group.groupId} className="">
              <div className="mt-1 mb-2 justify-content-between d-flex">
                <p className="mt-1 flex text-sm leading-5 text-gray-600">
                  <span className="fw-bold me-1">About: </span>
                  {group.description}
                </p>
              </div>
              {group.privacy === 'Private' ? (
                group.pendingMemberEmails?.includes(currentUserEmail) || requestedGroups.includes(group.groupId) ? ( // Disable after request
                  <button
                    className="btn btn-secondary ms-3 rounded-pill flex ms-auto"
                    disabled
                  >
                    Pending Request
                  </button>
                ) : (
                  <button
                    className="btn btn-warning ms-3 rounded-pill flex ms-auto"
                    onClick={() => handleRequestToJoin(group.groupId)}
                  >
                    Request to Join
                  </button>
                )
              ) : (
                <button
                  className="btn btn-success ms-3 rounded-pill flex ms-auto"
                  onClick={() => handleJoinGroup(group.groupId)}
                >
                  Join
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Install Prompt Modal */}
      <InstallPrompt
        deferredPrompt={deferredPrompt}
        isOpen={isInstallPromptOpen}
        onClose={() => setIsInstallPromptOpen(false)}
      />
    </div>
  );
};

export default AllGroups;
