import React, { useState, useEffect, useRef } from 'react';
import { fetchCommentsByPostId, createComment, updateComment, deleteComment, fetchUserProfileByEmail } from '../Api/apiService';
import { FaEllipsisV, FaPaperPlane } from 'react-icons/fa';

const Comments = ({ postId, currentUserEmail }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editCommentId, setEditCommentId] = useState(null);  // Track comment being edited
  const [deletedComment, setDeletedComment] = useState(null); // For undo delete
  const [showOptions, setShowOptions] = useState(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  // Loading state for comment submission
  const [authorProfiles, setAuthorProfiles] = useState({});
  const [showAllComments, setShowAllComments] = useState(false);
  const [sortOption, setSortOption] = useState('oldest');
  const optionsRef = useRef(null);
  const textareaRef = useRef(null);
  const maxLength = 300;  // Max length for comments

  // Get user data from local storage (adjust according to your app's logic)
  const userData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
  const userProfileImage = userData?.profileImageURL;
  const userInitials = userData ? `${userData.userFirstName.charAt(0)}${userData.userLastName.charAt(0)}` : '';

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const data = await fetchCommentsByPostId(postId);
        setComments(data);

        const profiles = await Promise.all(data.map(comment => fetchUserProfileByEmail(comment.author)));
        const profilesMap = profiles.reduce((acc, profile) => {
          acc[profile.userEmail] = profile;
          return acc;
        }, {});
        setAuthorProfiles(profilesMap);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [postId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        setIsAddingComment(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef, textareaRef]);

  const handleTextareaInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    setNewComment(e.target.value);
  };

  const getLocalISOString = () => {
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(now - timezoneOffset).toISOString().slice(0, -1); // remove the 'Z'
    return localISOTime;
  };

  const handleCreateOrUpdateComment = async () => {
    if (newComment.trim() === '') return;

    setIsLoading(true);  // Start loading
    try {
      if (editCommentId) {
        // Update existing comment
        await updateComment(editCommentId, { content: newComment });
        setComments(comments.map(comment => (comment.id === editCommentId ? { ...comment, content: newComment, edited: true } : comment)));
        setEditCommentId(null);  // Reset edit mode
        setNewComment('');  // Clear input field
      } else {
        // Create new comment
        const commentData = {
          postId,
          author: currentUserEmail,
          content: newComment,
          timestamp: getLocalISOString(),
        };
        const createdComment = await createComment(commentData);
        const profile = await fetchUserProfileByEmail(currentUserEmail);
        setComments([{ ...createdComment, authorProfile: profile }, ...comments]);
        setAuthorProfiles({ ...authorProfiles, [currentUserEmail]: profile });
        setNewComment('');  // Clear input field
      }
    } catch (error) {
      console.error('Error creating or updating comment:', error);
    }
    setIsLoading(false);  // End loading
    setIsAddingComment(false);
  };

  const handleEditComment = (comment) => {
    setEditCommentId(comment.id);  // Set edit mode with the comment id
    setNewComment(comment.content);  // Load the existing comment content into textarea
    setIsAddingComment(true);  // Show the input field
  };

  const handleDeleteComment = async (commentId) => {
    const commentToDelete = comments.find(comment => comment.id === commentId);
    try {
      await deleteComment(commentId);
      setDeletedComment(commentToDelete);
      setComments(comments.filter(comment => comment.id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  // Undo delete logic
  useEffect(() => {
    if (deletedComment) {
      const timer = setTimeout(() => {
        setDeletedComment(null);  // Clear the undo option after 5 seconds
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [deletedComment]);

  const toggleShowOptions = (id) => {
    setShowOptions(id === showOptions ? null : id);
  };

  const sortedComments = [...comments].sort((a, b) => {
    if (sortOption === 'recent') {
      return new Date(b.timestamp) - new Date(a.timestamp);
    } else if (sortOption === 'oldest') {
      return new Date(a.timestamp) - new Date(b.timestamp);
    }
    return 0;
  });

  const commentsToShow = showAllComments ? sortedComments : sortedComments.slice(0, 2);

  return (
    <div className="">
      {/* Undo delete notification */}
      {deletedComment && (
        <div className="bg-yellow-100 text-yellow-700 p-2 rounded mb-4">
          <span>Comment deleted.</span>
          <button
            className="ml-2 underline"
            onClick={() => {
              setComments([deletedComment, ...comments]);
              setDeletedComment(null);
            }}
          >
            Undo
          </button>
        </div>
      )}

      {/* Comment sorting button */}
      {/* <p className='font-semibold text-lg'>Post Comments</p> */}
      <div className="flex justify-end border-gray-400 mb-4">
       
        <button
          className="mt-3 inline-flex rounded-pill btn btn-sm btn-outline-dark "
          onClick={() => setSortOption(sortOption === 'recent' ? 'oldest' : 'recent')}
        >
          Sort by {sortOption === 'recent' ? 'Oldest' : 'Recent'}
        </button>
      </div>

      {/* Display comments */}
      {commentsToShow.map((comment) => {
        const authorProfile = authorProfiles[comment.author];
        const authorProfileImage = authorProfile?.profileImageURL;
        const authorInitials = authorProfile ? `${authorProfile.userFirstName.charAt(0)}${authorProfile.userLastName.charAt(0)}` : '';
        const authorName = authorProfile ? `${authorProfile.userFirstName} ${authorProfile.userLastName}` : comment.author;

        return (
          <div key={comment.id} className="relative pb-3">
            <div className="rounded-lg flex space-x-4 relative items-start">
              <div className="relative flex-shrink-0 h-10 w-10 rounded-full bg-gray-400 ring-4 ring-white flex items-center justify-center overflow-hidden">
                {authorProfileImage ? (
                  <img
                    className="h-full w-full object-cover"
                    src={authorProfileImage}
                    alt={`${authorName}'s profile`}
                  />
                ) : (
                  <span className="text-white font-semibold">{authorInitials}</span>
                )}
              </div>

              <section className=''>
                <div className="flex-1 bg-gray-100 rounded-card inline-flex overflow-auto">
                  <div className="p-2 text-sm whitespace-pre-wrap text-break">
                    <a href="#" className="font-medium text-gray-900">
                      {authorName}
                    </a>
                    <p className='text-wrap md:text-balance'>{comment.content}</p>
                  </div>
                </div>
                <p className="mt-0.5 text-xs text-gray-500 px-2">
                  {new Date(comment.timestamp).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short', hour12: true })}
                  {comment.edited && <span className="text-xs text-gray-400"> (edited)</span>}
                </p>
              </section>

              {comment.author === currentUserEmail && (
                <div className="ml-auto relative">
                  <button
                    onClick={() => toggleShowOptions(comment.id)}
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    aria-label="Options"
                  >
                    <FaEllipsisV />
                  </button>
                  {comment.id === showOptions && (
                    <div ref={optionsRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-sm z-10">
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => {
                          handleEditComment(comment);  // Set edit mode
                          setShowOptions(null);  // Hide options
                        }}
                      >
                        Edit
                      </button>
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleDeleteComment(comment.id)}>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}

      {/* "Show more" button */}
      {comments.length > 2 && (
        <div className="text-sm text-gray-500 font-semibold cursor-pointer mb-4" onClick={() => setShowAllComments(!showAllComments)}>
          {showAllComments ? 'Show less comments' : 'Show more comments'}
        </div>
      )}

      {/* Comment input area */}
      <div className="flex items-start space-x-3">
        {/* Avatar of the current user */}
        <div className="relative flex-shrink-0 h-10 w-10 rounded-full bg-gray-400 ring-4 ring-white flex items-center justify-center overflow-hidden">
          {userProfileImage ? (
            <img
              className="h-full w-full object-cover"
              src={userProfileImage}
              alt={`${userData.userFirstName}'s profile`}
            />
          ) : (
            <span className="text-white font-semibold">{userInitials}</span>
          )}
        </div>

        {/* Comment input field */}
        <div className={`bg-gray-200 rounded-card flex-grow relative ${editCommentId ? 'edit-mode' : ''}`} ref={textareaRef}>
          <textarea
            className="w-full py-2 px-3 rounded-card bg-gray-200 resize-none focus:outline-none"
            placeholder={editCommentId ? "Edit your comment..." : "Add a comment..."}
            value={newComment}
            onChange={handleTextareaInput}
            onFocus={() => setIsAddingComment(true)}
            rows={1}  // Auto-expand textarea
          />
          {isAddingComment && (
            <>
            <div className='d-flex justify-content-end mb-2'>
            {editCommentId && (
                <button className="mx-3 right-16 bottom-3 text-gray-500 hover:bg-white rounded-full text-sm" onClick={() => {
                  setEditCommentId(null);  // Exit edit mode
                  setNewComment('');  // Clear the comment input field
                }}>
                  Cancel
                </button>
                
              )}

              <button className="top-5 mx-3  bottom-3 text-blue-500 hover:bg-white rounded-full text-2xl" onClick={handleCreateOrUpdateComment} disabled={isLoading}>
                {isLoading ? <span className="spinner" /> : <FaPaperPlane />}
              </button>
              
              </div>
            </>
          )}
        </div>

        {/* Character counter */}
        <div className="text-sm text-gray-500 text-right">
          {newComment.length}/{maxLength}
        </div>
      </div>
    </div>
  );
};

export default Comments;
