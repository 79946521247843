import React from 'react';
import MapView from '../components/Group Comps/mapView';
import { fetchGroupById } from '../Api/apiService';
import { useNavigate, useParams } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const MapPage = () => {
  const [groupData, setGroupData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { groupId } = useParams();  // Grab groupId from URL parameters
  const navigate = useNavigate();

  React.useEffect(() => {
    const loadGroupData = async () => {
      try {
        const data = await fetchGroupById(groupId);
        setGroupData(data);
      } catch (error) {
        console.error('Failed to fetch group data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (groupId) loadGroupData();
  }, [groupId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <p className="text-lg font-medium text-gray-600">Loading map data...</p>
      </div>
    );
  }

  return (
    <div className="bg-white fixed-top">
      {/* Left-aligned back button */}
          <button
      style={{ pointerEvents: 'auto', position: 'relative', zIndex: 10 }}
      className="pt-5 flex items-center font-bold mt-3"
      onClick={() => navigate(-1)} // Navigates back to the previous page
    >
      <NavigateBeforeIcon className="mr-1 " /> Back
    </button>


      {/* Main content area */}
      <main className="bg-gray-100 shadow-lg rounded-md">
        <MapView groupData={groupData} />
      </main>
    </div>
  );
};

export default MapPage;
