import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const CreateAccount = () => {
  const navigate = useNavigate();
  const { createUser } = useAuth();
  const [formData, setFormData] = useState({
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    password: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = {
        userFirstName: formData.userFirstName,
        userLastName: formData.userLastName,
        userEmail: formData.userEmail,
        password: formData.password,
      };

      await createUser(userData.userEmail, userData.password, userData);
      navigate('/login');
    } catch (error) {
      if (error.message === 'Email already in use') {
        setError('This email is already in use.');
      } else {
        setError('Failed to create account. Please try again later.');
      }
      console.error('Error creating account:', error);
    }
  };

  return (
    <div className="container">
      <h2>Create Account</h2>
      {error && <p className="text-danger">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="userFirstName" className="form-label">First Name</label>
          <input
            type="text"
            className="form-control"
            id="userFirstName"
            name="userFirstName"
            value={formData.userFirstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="userLastName" className="form-label">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="userLastName"
            name="userLastName"
            value={formData.userLastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="userEmail" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="userEmail"
            name="userEmail"
            value={formData.userEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Create Account</button>
      </form>
    </div>
  );
};

export default CreateAccount;