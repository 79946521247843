import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import LeaveGroup from '../components/LeaveGroup';
import { fetchGroupById, fetchAllUserInfo, fetchUserProfileByEmail, updateGroup, updateUser } from '../Api/apiService';
import Posts from '../components/Post';
import { FaBell, FaEdit, FaHome, FaFileAlt, FaInfoCircle, FaBullseye,FaShieldAlt,FaUserShield  } from 'react-icons/fa';
import Members from '../components/Members';
import ShareGroup from '../components/shareGroup';
import MapView from '../components/Group Comps/mapView';
import { TbRefreshAlert } from "react-icons/tb";
import { HiDocumentReport } from "react-icons/hi";


const MemberGroup = () => {
  const { groupId } = useParams();
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [groupData, setGroupData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [adminProfiles, setAdminProfiles] = useState([]); // Store admin profiles
  const [loadingText, setLoadingText] = useState(''); // State for animated loading text
  const [activeTab, setActiveTab] = useState('details'); // State for active tab
  const [quote, setQuote] = useState(''); // State for the rotating quote
  const navigate = useNavigate(); // Allows programmatic navigation

  // List of motivational quotes
  const motivationalQuotes = [
    "Teamwork makes the dream work. – John C. Maxwell",
    "In a gentle way, you can shake the world. – Mahatma Gandhi",
    "No one can whistle a symphony. It takes a whole orchestra to play it. – H.E. Luccock",
    "Kindness is a language which the deaf can hear and the blind can see. – Mark Twain",
    "Individually, we are one drop. Together, we are an ocean. – Ryunosuke Satoro",
    "Success is best when it’s shared. – Howard Schultz",
    "Alone we can do so little; together we can do so much. – Helen Keller",
    "Great things in business are never done by one person. They’re done by a team of people. – Steve Jobs",
    "The strength of the team is each individual member. The strength of each member is the team. – Phil Jackson",
    "Coming together is a beginning, staying together is progress, and working together is success. – Henry Ford"
  ];

  useEffect(() => {
    const loadGroupData = async () => {
      try {
        const group = await fetchGroupById(groupId);
        setGroupData(group);

        // Fetch admin profiles based on their emails
        const adminProfiles = await Promise.all(group.adminEmails.map(email => fetchUserProfileByEmail(email)));
        setAdminProfiles(adminProfiles);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    const loadUserData = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const user = allUserInfo.find(user => user.userEmail === currentUserEmail);
        setUserData(user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    // Set a random quote on component mount
    const randomQuote = motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)];
    setQuote(randomQuote);

    loadGroupData();
    loadUserData();
  }, [groupId, currentUserEmail]);

  useEffect(() => {
    if (groupData && groupData.alert === 'Active') {
      const loadingStages = ['', '.', '..', '...']; // Different stages for loading animation
      let currentStage = 0;

      const interval = setInterval(() => {
        setLoadingText(loadingStages[currentStage]);
        currentStage = (currentStage + 1) % loadingStages.length; // Cycle through stages
      }, 500); // Change every 500ms

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [groupData]);

  if (!groupData || !userData) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  const isAdmin = groupData.adminEmails.includes(currentUserEmail);
  const isAnAdmin = groupData.adminEmails;

  const handleGetStatusClick = async () => {
    try {
      const updatedGroupData = { ...groupData, alert: 'Active' };
      await updateGroup(groupId, updatedGroupData);
      await updateGroupAlertStatus(groupId);
      navigate(`/Group/${groupId}`); // Redirect to the group page after update
    } catch (error) {
      console.error('Error updating group alert:', error);
    }
  };

  const updateGroupAlertStatus = async (groupId) => {
    try {
      const groupData = await fetchGroupById(groupId);
      const updatedGroupData = { ...groupData, alert: 'Active' };
      await updateGroup(groupId, updatedGroupData);

      const userEmails = groupData.memberEmails;
      const allUserInfo = await fetchAllUserInfo();
      const userData = allUserInfo.filter(user => userEmails.includes(user.userEmail));

      const updatePromises = userData.map(async (user) => {
        await updateUser(user.id, {
          ...user,
          activeGroupAlertCounts: user.activeGroupAlertCounts + 1,
        });
      });
      await Promise.all(updatePromises);

      const currentUser = localStorage.getItem('currentUserEmail');
      let groupDataLocal = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));
      let targetGroupIndex = groupDataLocal.findIndex(group => group.groupId.toString() === groupId);
      if (targetGroupIndex !== -1) {
        groupDataLocal[targetGroupIndex].alert = 'Active';
      }
      localStorage.setItem(`groupData-${currentUser}`, JSON.stringify(groupDataLocal));
    } catch (error) {
      console.error('Error updating group alert status:', error);
      throw error;
    }
  };

  const tabs = [
    { name: 'Details', value: 'details' },
    { name: 'Post', value: 'post' },
    { name: 'Members', value: 'members' },
    { name: 'Maps', value: 'maps' },
  ];

  return (
    <main className="lg:container mx-auto">
      <div className="navbarCst px-4 pb-4 pt-5 shadow lg:rounded-t-lg">
        <div>
          <div className="mt-5 flex justify-between">
            <Link className="shadow btn btn-light mr-2 rounded-full p-3" to="/">
              <FaHome className="text-xl text-dark" />
            </Link>
            {currentUserEmail !== groupData.ownerEmail && (
              <LeaveGroup groupId={groupId} />
            )}
          </div>
        </div>
      </div>

      <div className="pt-3 px-4 bg-white shadow-sm flex justify-content-between">
        <div>
          <div className="flex mb-2">
            <h1 className="text-2xl text-dark font-bold">{groupData.groupName}</h1>
            {isAdmin && (
              <Link
                to={`/groups/${groupId}/edit?groupOwner=${groupData.ownerEmail}&currentUser=${currentUserEmail}`}
                className=" hover:text-blue-700 "
              >
                <FaEdit className="ms-3 text-2xl inline-block mr-1 text-dark" />
              </Link>
            )}
          </div>
          {/* Display the rotating quote here */}
          <p className="text-gray-600 text-sm italic pe-12">{quote}</p>
        </div>
        <div className="d-flex">
          <div className="">
            <ShareGroup groupId={groupId} buttonTextColor="text-black" />
          </div>
        </div>
      </div>

      <div className="bg-white py-3 px-4 d-flex justify-content-between border-bottom">
      {isAdmin && (
          <div className="justify-content-center d-flex mt-2">
            {groupData.alert === 'Inactive' || groupData.alert === 'Not Active' ? (
              <button
                onClick={handleGetStatusClick}
                className="text-1xl rounded p-2 bg-red-500 text-white hover:text-blue-700"
              >
                <FaBell className="inline mr-2" />
               Get Status
              </button>
            ) : (
              <Link
                to={`/Group/${groupId}`}
                className="text-sm rounded p-2 px-2 bg-green-700 text-white hover:text-blue-700"
              >
                <HiDocumentReport className="inline text-xl mr-2 " />
                  View Report
              </Link>
            )}
          </div>
        )}

        <div className="mt-2 flex items-center">
        {groupData.alert === 'Active' && (
              <p className="flex space-x-2 text-green-700 text-sm font-semibold">
                <span className=' text-2xl'><TbRefreshAlert /></span> <span>Retrieving status now</span>{loadingText}
              </p>
            )}
        </div>

       
      </div>

      <div className="flex space-x-4 px-4  bg-white" aria-label="Tabs">
      {tabs.map((tab) => (
          <button
            key={tab.value}
            onClick={() =>
              tab.value === 'maps'
                ? navigate(`/status-map/${groupId}`)
                : setActiveTab(tab.value)
            }
            className={`p-2 pt-4 px-2 border-b-2 font-semibold text-sm ${
              activeTab === tab.value
                ? 'border-gray-600 text-gray-600 '
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            {tab.name}
          </button>
        ))}
      </div>

      <div className="pt-3">
        {activeTab === 'details' && (
          <div className="mx-2 bg-white p-4 rounded-lg shadow-sm mt-3">
            <div className="flex mt-2">
              <FaInfoCircle className="mr-2 text-xl" />
              <p className="text-gray-700 font-bold text-sm">INTRO</p>
            </div>
            <p className="mt-2 text-gray-700 text-sm">
              {groupData.groupName} is a{' '}
              <span className="lowercase font-semibold">{groupData.privacy}</span> group created by{' '}
              {groupData.ownerName} on{' '}
              {new Date(groupData.createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
              .
            </p>

            <div className="flex mt-4">
              <FaBullseye className="mr-2 text-xl" />
              <p className="text-gray-700 font-bold text-sm">PURPOSE</p>
            </div>
            <p className="mt-2 text-gray-700 text-sm">{groupData.description}</p>

            <div className="flex mt-4">
              <FaUserShield  className="mr-2 text-xl" />
              <p className="text-gray-700 font-bold text-sm">Owner</p>
            </div>
            <p className="text-gray-600 text-sm "><span className='font-semibold '>Group Owner | </span>{groupData.ownerName}</p>
            <p className="text-gray-600 text-sm "><span className='font-semibold '>Owner Email | </span>{groupData.ownerEmail}</p>

            <div className="flex mt-4">
              <FaShieldAlt className="mr-2 text-xl" />
              <p className="text-gray-700 font-bold text-sm">Admins</p>
            </div>
            {adminProfiles.map((admin) => (
              <div className='mb-3 mt-1' key={admin.userEmail}>
                <p className="text-gray-600 text-sm "><span className='font-semibold '>Admin | </span>{admin.userFirstName} {admin.userLastName}</p>
                <p className="text-gray-600 text-sm "><span className='font-semibold '>Admin Email | </span>{admin.userEmail}</p>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'members' && (
          <div className="mx-2">
            <Members
              groupId={groupId}
              memberEmails={groupData.memberEmails}
              pendingMemberEmails={groupData.pendingMemberEmails}
              memberCount={groupData.memberCount}
              currentUserEmail={currentUserEmail}
              groupOwner={groupData.ownerEmail}
              isAnAdmin={isAnAdmin}
              groupData={groupData}
            />
          </div>
        )}

        {activeTab === 'post' && (
          <div className="mx-2 lg:w-1/2">
            <Posts groupId={groupId} currentUserEmail={currentUserEmail} />
          </div>
        )}

    {activeTab === 'maps' && (
          <div className="">
            <MapView groupData={groupData} />
          </div>
        )}
      </div>
    </main>
  );
};

export default MemberGroup;
