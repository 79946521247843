import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

const TermsAndPrivacy = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white px-6 py-10 lg:px-8">
    <button
            onClick={() => navigate(-1)}
            className="text-gray-600 hover:text-gray-900 mb-4"
          >
            &larr; Back
          </button>

      <div className="mx-auto max-w-4xl text-gray-700">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">SitPrep Terms and Services</h1>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Terms of Use Agreement</h2>
          <div className="p-4 border bg-gray-50 rounded-md">
            <p className="text-lg mb-4">Effective on 2021-04-20.</p>
            <p className="mb-4">
              <strong>1. Subscribers:</strong><br />
              If you subscribed through your Apple ID, refunds are handled by Apple/Google, not SitPrep. You can request a refund from Apple through your Apple ID account or at <a href="https://getsupport.apple.com" className="text-indigo-600">https://getsupport.apple.com</a>. For other users, contact SitPrep customer service at <a href="mailto:contactus@sitprep.app" className="text-indigo-600">contactus@sitprep.app</a>.
            </p>
            <p className="mb-4">
              <strong>1a. INTRODUCTION:</strong><br />
              By accessing or using our Services on use.sitprep.app (the “Website”), the SitPrep mobile application (the “App”), or any other platforms or services, you agree to these Terms of Use. This Agreement applies to anyone using our Services.
            </p>
            <p>
              <strong>Note:</strong> Your use of the services is subject to our <a href="/privacy-policy" className="text-indigo-600">Privacy Policy</a>. If you disagree with any terms, do not use our services.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">2. Account Eligibility and Responsibilities</h2>
          <div className="p-4 border bg-gray-50 rounded-md">
            <ul className="list-disc pl-6 space-y-3">
              <li>You must be at least 18 years old or have parental permission to use our services.</li>
              <li>You are responsible for your actions and must comply with all applicable laws.</li>
              <li>Respect other users and maintain a strong password for account security.</li>
              <li>You will not use our services to misrepresent, disrupt, or engage in harmful activities.</li>
            </ul>
            <p className="mt-4">
              Violating any of these responsibilities may result in suspension or termination of your account.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">3. Content</h2>
          <div className="p-4 border bg-gray-50 rounded-md">
            <p>
              While using our Services, you will have access to content you upload (“Your Content”), content other users upload (“Member Content”), and content provided by SitPrep (“Our Content”).
            </p>
            <div className="mt-4">
              <strong>3a. Your Content:</strong>
              <p className="mt-2">You are solely responsible for Your Content. You grant SitPrep the license to use Your Content as described in the terms.</p>
            </div>
            <div className="mt-4">
              <strong>3b. Member Content:</strong>
              <p className="mt-2">You have no rights to other users' content and may only use it for the purposes outlined by the service.</p>
            </div>
            <div className="mt-4">
              <strong>3c. Our Content:</strong>
              <p className="mt-2">SitPrep retains ownership of all intellectual property within the services and grants you a limited license to access and use it as per the Terms.</p>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">4. Inappropriate Content and Misconduct</h2>
          <div className="p-4 border bg-gray-50 rounded-md">
            <p>We are committed to maintaining a positive SitPrep community. Inappropriate content or misconduct may result in account suspension or termination. Please report any misconduct by contacting <a href="mailto:contactus@sitprep.app" className="text-indigo-600">contactus@sitprep.app</a>.</p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">5. Privacy</h2>
          <div className="p-4 border bg-gray-50 rounded-md">
            <p>
              By using our services, you agree to the collection, use, and sharing of your personal data as outlined in our <a href="/privacy-policy" className="text-indigo-600">Privacy Policy</a>.
            </p>
          </div>
        </div>

        <div className="text-center text-gray-500">
          <p>&copy; 2023 SitPrep, LLC</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndPrivacy;
