import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Backdrop from '@material-ui/core/Backdrop';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(3),
    backgroundColor: '#00A35E ', // Green color
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  fabContainer: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(4),
  },
  createGroupFab: {
    width: theme.spacing(5), // Adjust the size as needed
    height: theme.spacing(5), // Adjust the size as needed
    marginBottom: theme.spacing(2),
    backgroundColor: '#000', // Green color
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  shareSN: {
    width: theme.spacing(5), // Adjust the size as needed
    height: theme.spacing(5), // Adjust the size as needed
    marginBottom: theme.spacing(2),
    backgroundColor: '#4caf50', // Green color
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  closeFab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: '#000', // Green color
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
}));

const HomeFAB = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showCloseFab, setShowCloseFab] = useState(false);

  const handleFabClick = () => {
    setOpen(!open);
    setShowCloseFab(!showCloseFab);
  };

  const handleCloseBackdrop = () => {
    setOpen(false);
    setShowCloseFab(false);
  };

  return (
    <div className='mb-5 pb-5'>
      <Fab className={classes.fab} onClick={handleFabClick}>
        <AddIcon />
      </Fab>
      <Backdrop className={classes.backdrop} open={open} onClick={handleCloseBackdrop}>
        <div className={classes.fabContainer}>
          <div>
            <div className='d-flex '>
            <p className='me-2 h5'>Create Group</p>
            <Fab
              to={{
                pathname: '/create-group',
              
              }}
              className={`${classes.createGroupFab} mb-3`}
              component={Link}
            >
              <GroupIcon />
            </Fab>

            </div>
          </div>
          <div className="mb-3">
          {/* <div className='d-flex '>
          <p className='me-2 h5'>Create</p>
            <Fab to={`/create-group`} className={`${classes.shareSN} mb-3`} component={Link}>
              <GroupIcon />
            </Fab>
            </div> */}
          </div>
        </div>
      </Backdrop>
      {showCloseFab && (
        <Fab className={classes.closeFab} onClick={handleCloseBackdrop}>
          <CloseIcon />
        </Fab>
      )}
    </div>
  );
};

export default HomeFAB;
