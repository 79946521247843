import React from 'react';

const Navbar = () => {
  return (
    <nav className="navbar recBlu fixed-top">
      <div className="container-fluid">
        <div className="px-3 my-2">
          <img src="./images-sitprep/SitPrep Logo White.png" width="150" alt="SitPrep Logo" />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <button className="navbar-toggler bg-white txtLiteOrg" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon text-white bg-white"></span>
          </button>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header recBlu">
              <div className="mt-3">
                <img src="./images-sitprep/SitPrep Logo White.png" width="150" alt="SitPrep Logo" />
              </div>
              <button type="button" className="btn-close text-white bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body recBlu">
              <p className="fs-4 text-white font-weight-bold pt-4">Emergency Plan</p>
              <div className="pl-2 my-2 d-flex text-white rounded orgOppate">
                <span className="material-icons pr-3 text-white">dashboard</span>
                <a className="mt-1 pl-2 text-white d-flex btn font-weight-bold" href="./dashboard Main.html">Overview</a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">kitchen</span>
                <a className="mt-1 pl-2 txtOppate nav-link" href="./Food Supply.html" role="tab">Create Food Supply</a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate rounded evacForm">
                <span className="material-icons pr-3 txtOppate">map</span>
                <a className="mt-1 pl-2 txtOppate nav-link" href="./create_evac_plan.html">Plan Evacuation</a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate rounded">
                <span className="material-icons pr-3 txtOppate">contact_phone</span>
                <a className="mt-1 pl-2 txtOppate d-flex nav-link" href="./emergency_contacts.html">Emergency Contacts</a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">backpack</span>
                <a className="mt-1 pl-2 txtOppate d-flex nav-link" href="./Additional_Supplies.html" role="tab">Additional Supplies</a>
              </div>
              <p className="fs-4 txtOppate pt-4">Long Term</p>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">food_bank</span>
                <a href="../assets/storageCalc.html" className="mt-1 nav-link pl-2 txtOppate d-flex">Food Storage</a>
              </div>
              <p className="fs-4 txtOppate pt-4">Info</p>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate"><span className="material-symbols">support_agent</span></span>
                <a className="mt-1 pl-2 txtOppate d-flex nav-link" href="../assets/sai.html">Ask Sai</a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="material-icons pr-3 txtOppate">emoji_emotions</span>
                <a className="mt-1 pl-2 txtOppate d-flex nav-link" href="https://g.page/r/CcCOilHmZderEB0/review" target="_blank" rel="noopener noreferrer">Was This Tool Helpful?</a>
              </div>
              <div className="my-2 pl-2 d-flex txtOppate">
                <span className="mb-3 material-icons txtOppate"><span className="material-icons txtOppate">contact_mail</span></span>
                <ul className="text-left dropdown">
                  <a className="mt-1 txtOppate dropdown-toggle nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Contact Us</a>
                  <div className="dropdown-menu text-secondary" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item homenav text-secondary" href="https://www.facebook.com/groups/2648649645427351/" target="_blank" rel="noopener noreferrer">SitPrep Community</a>
                    <a className="dropdown-item homenav text-secondary" href="https://www.instagram.com/sit.prep/" target="_blank" rel="noopener noreferrer">SitPrep On Instagram</a>
                    <a className="dropdown-item homenav text-secondary" href="https://www.facebook.com/SitPrep" target="_blank" rel="noopener noreferrer">SitPrep On Facebook</a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
