import React from 'react';
import { Link } from 'react-router-dom';

const EmbeddedSite = () => {
  return (
    <div className="relative w-full h-screen">
    
      <div className="w-full h-full pt-16"> {/* Adds space for the button */}
      <Link 
        to="/" 
        className="absolute top-4 left-4 bg-white text-black py-2 px-4 rounded shadow-lg "
      >
        Back
      </Link>
        <iframe 
          src="https://www.sitprep.app" 
          title="SITPrep"
          className="w-full h-full border-none"
        />
      </div>
    </div>
  );
};

export default EmbeddedSite;
