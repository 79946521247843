import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NotificationList from '../components/Notification Comps/NotificationList';
import { fetchAllGroups, fetchUserProfileByEmail } from '../Api/apiService';
import { FaHome, FaFilter } from 'react-icons/fa';

function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);
  const [archivedNotifications, setArchivedNotifications] = useState([]);
  const [userFullName, setUserFullName] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentUserEmail = searchParams.get('userEmail');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const groups = await fetchAllGroups();
        const userProfile = await fetchUserProfileByEmail(currentUserEmail);

        const newNotifications = groups.reduce((acc, group) => {
          if (group.memberEmails.includes(currentUserEmail) && group.alert === 'Active') {
            acc.push({
              id: `${group.groupId}-alert`,
              type: 'groupAlert',
              groupAdminName: group.ownerName,
              groupName: group.groupName,
              timestamp: new Date().toISOString(),
            });
          }

          if (group.adminEmails.includes(currentUserEmail)) {
            group.memberEmails.forEach(memberEmail => {
              if (memberEmail !== currentUserEmail) {
                acc.push({
                  id: `${group.groupId}-${memberEmail}`,
                  type: 'newMember',
                  newUser: memberEmail,
                  groupName: group.groupName,
                  timestamp: new Date().toISOString(),
                });
              }
            });
          }
          return acc;
        }, []);

        setNotifications(newNotifications);
        setUserFullName(`${userProfile.userFirstName} ${userProfile.userLastName}`);

        const savedArchivedNotifications = JSON.parse(localStorage.getItem('archivedNotifications')) || [];
        setArchivedNotifications(savedArchivedNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (currentUserEmail) {
      fetchData();
    }
  }, [currentUserEmail]);

  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
    localStorage.setItem('archivedNotifications', JSON.stringify(archivedNotifications));
  }, [notifications, archivedNotifications]);

  const clearNotifications = () => {
    setArchivedNotifications([...archivedNotifications, ...notifications]);
    setNotifications([]);
  };

  const clearNotification = (id) => {
    const notification = notifications.find(notification => notification.id === id);
    setArchivedNotifications([...archivedNotifications, notification]);
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  const clearArchivedNotification = (id) => {
    setArchivedNotifications(archivedNotifications.filter(notification => notification.id !== id));
  };

  const clearAllArchivedNotifications = () => {
    setArchivedNotifications([]);
  };

  return (
    <div className="px-4 pt-5">
      <div className="flex justify-between items-center mt-4 mb-5">
        <button
          className="px-4 bg-gray-600 text-white rounded"
          onClick={() => navigate('/')}
        >
          <FaHome />
        </button>
        <button
          className="px-4 bg-blue-600 text-white rounded"
          onClick={() => setShowArchived(!showArchived)}
        >
          <FaFilter /> {showArchived ? 'Current' : 'Archived'}
        </button>
      </div>
      {showArchived ? (
        <>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">Archived Notifications</h1>
            <button
              className="px-4 bg-red-600 text-white rounded"
              onClick={clearAllArchivedNotifications}
            >
              Clear All
            </button>
          </div>
          <NotificationList
            notifications={archivedNotifications}
            userFullName={userFullName}
            clearNotification={clearArchivedNotification}
          />
        </>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">Notifications</h1>
            <button
              className="px-4 bg-red-600 text-white rounded"
              onClick={clearNotifications}
            >
              Clear All
            </button>
          </div>
          <NotificationList
            notifications={notifications}
            userFullName={userFullName}
            clearNotification={clearNotification}
          />
        </>
      )}
    </div>
  );
}

export default NotificationsPage;
