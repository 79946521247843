import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchAllUserInfo,
  fetchAllGroups,
  updateUser,
  updateGroup,
} from '../Api/apiService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const LeaveGroup = ({ groupId }) => {
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [joinedGroups, setJoinedGroups] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJoinedGroups = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const authenticatedUser = allUserInfo.find(user => user.userEmail === currentUserEmail);
        
        if (authenticatedUser) {
          const allGroups = await fetchAllGroups();
          const joinedGroupsData = allGroups.filter(group => 
            authenticatedUser.joinedGroupIDs.includes(group.groupId)
          );

          setJoinedGroups(joinedGroupsData);
        } else {
          setError('User not found');
        }
      } catch (error) {
        console.error('Error fetching joined groups:', error);
        setError('Error fetching joined groups. Please try again later.');
      }
    };

    fetchJoinedGroups();
  }, [currentUserEmail]);

  const handleLeaveGroup = async (groupId) => {
    try {
      const allUserInfo = await fetchAllUserInfo();
      const authenticatedUser = allUserInfo.find(user => user.userEmail === currentUserEmail);

      if (authenticatedUser) {
        const userDocRef = authenticatedUser.id;

        // Remove group from user's joinedGroupIDs
        const updatedUser = {
          ...authenticatedUser,
          joinedGroupIDs: authenticatedUser.joinedGroupIDs.filter(id => id !== groupId)
        };
        await updateUser(userDocRef, updatedUser);

        // Retrieve group data from backend to ensure we are merging the current state
        const allGroups = await fetchAllGroups();
        const groupToUpdate = allGroups.find(group => group.groupId === parseInt(groupId, 10));

        if (groupToUpdate) {
          // Remove user from group members
          const updatedGroup = {
            ...groupToUpdate,
            memberEmails: groupToUpdate.memberEmails.filter(email => email !== authenticatedUser.userEmail)
          };
          await updateGroup(groupId, updatedGroup);

          // Update local storage with the latest user data
          localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(updatedUser));

          // Fetch latest group data and update local storage
          const updatedGroupsData = await fetchAllGroups();
          const joinedGroups = updatedGroupsData.filter(group => group.memberEmails.includes(currentUserEmail) && !group.adminEmails.includes(currentUserEmail));
          localStorage.setItem(`linkedGroupData-${currentUserEmail}`, JSON.stringify(joinedGroups));

          // Update local state to remove the left group
          setJoinedGroups(prevGroups => prevGroups.filter(group => group.groupId !== groupId));

          navigate('/');

          // Prompt success message
          alert('You have successfully left the group.');
        } else {
          setError('Group not found');
        }
      } else {
        setError('User not found');
      }
    } catch (error) {
      console.error('Error leaving group:', error);
      setError('An error occurred while leaving the group. Please try again later.');
    }
  };

  return (
 
    <button
      className="shadow-sm btn  rounded my-3 btn-dark"
      onClick={() => handleLeaveGroup(groupId)}
    >
      Leave Group
         {/* <ExitToAppIcon className="text-5xl inline-block mr-1 text-white" /> */}
    </button>
  )
  ;
};

export default LeaveGroup;
