import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import SignInWithGoogle from '../signInWithGoogle';
import { Link } from 'react-router-dom';

const Register = () => {
  const { createUserMeth } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userData = {
        userFirstName: formData.firstName,
        userLastName: formData.lastName,
        userEmail: formData.email,
        password: formData.password,
        userStatus: 'NO RESPONSE',
        statusColor: 'custom-bg-purple',
        profileImageURL: '',
        fcmtoken: '',
        managedGroupIDs: [],
        joinedGroupIDs: [],
        groupAlert: 'All',
        subscription: 'Basic',
        subscriptionPackage: 'Monthly',
        dateSubscribed: new Date().toISOString(),
        phone: '',
        title: '',
        address: ''
      };

      await createUserMeth(userData.userEmail, userData.password, userData);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
    } catch (error) {
      if (error.message === 'Email already in use') {
        setError('This email is already in use.');
      } else {
        setError('Failed to create account. Please try again later.');
      }
      console.error('Error creating account:', error);
    }
  };

  return (
    <div className="bg-white py-8 px-4 sm:px-6 lg:px-8 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">Free Sign Up</h2>
      {error && <p className="text-red-600 mb-4 text-center">{error}</p>}
      <form onSubmit={handleCreateAccount} className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Free Sign Up</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                First Name
              </label>
              <div className="mt-2">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  className="p-1.5 block w-full rounded-md border-gray-300 ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" />
               
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                Last Name
              </label>
              <div className="mt-2">
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="p-1.5 block w-full rounded-md border-gray-300 ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" />
               
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="p-1.5 block w-full rounded-md border-gray-300 ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" />
               
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  className="p-1.5 block w-full rounded-md border-gray-300 ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" />
               
              </div>
              <p className="mt-2 text-sm text-gray-500">
                  Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emojis.
                </p>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                  className="p-1.5 block w-full rounded-md border-gray-300 ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <p className="text-sm text-gray-500 mt-4">
          By creating an account or signing in, you agree to our{' '}
          <Link className="text-indigo-600 hover:text-indigo-500" to="/terms-privacy">
            Terms of Use and Privacy Policy.
          </Link>
        </p>

        <button
          type="submit"
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-4 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Create Status Now Account
        </button>
      </form>

      <div className="mt-6 flex items-center justify-between">
        <hr className="w-full border-gray-300" />
        <p className="px-3 text-gray-500">OR</p>
        <hr className="w-full border-gray-300" />
      </div>

      <div className="mt-6">
        <SignInWithGoogle />
      </div>
    </div>
  );
};

export default Register;
