// UserAvatar.js
import React from 'react';

const GroupImage = ({ group }) => {
    // Function to get the image path based on group type
    const getImagePath = (groupType) => {
      switch (groupType) {
        case 'Business':
          return '/images/Business.svg';
        case 'Church':
          return '/images/Hospital.svg';
        case 'Extended Family':
          return '/images/Family.svg';
        case 'Friends':
          return '/images/Friend.svg';
        default:
          return '/images/Neighbor.svg';  // Default image if group type is not recognized
      }
    };
  
  return (


   <>
    <img
                  className='shadow-sm'
                  src={getImagePath(group.groupType)}  // Set image path dynamically based on group type
                  alt={group.groupType}
                  style={{ width: '50px', height: '50px', borderRadius: '15px' }}
                />
   </>
  );
};

export default GroupImage;
