import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, UserCircleIcon, DocumentTextIcon } from '@heroicons/react/20/solid';
import { CgAddR } from "react-icons/cg";
import { fetchAllGroups } from '../Api/apiService'; // Assuming this function fetches the updated groups

const AppendGroup = ({ groupData, currentUserEmail }) => {
  const [currentGroupData, setCurrentGroupData] = useState(groupData || []);
  const [user, setUser] = useState(null); // Add state for the user

  // Fetch user data from localStorage
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
    setUser(storedUserData); // Set user data
  }, [currentUserEmail]);

  // Function to fetch updated group data from the backend
  const updateGroupData = async () => {
    try {
      const updatedGroups = await fetchAllGroups();
      const managedGroups = updatedGroups.filter(group => group.adminEmails.includes(currentUserEmail));
      setCurrentGroupData(managedGroups);
    } catch (error) {
      console.error('Error fetching updated group data:', error);
    }
  };

  useEffect(() => {
    setCurrentGroupData(groupData); // Initially set group data from props
  }, [groupData]);

  // Polling every 10 seconds to fetch updated group data
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateGroupData(); // Fetch updated data
    }, 30000); // Fetch every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [currentUserEmail]);

  // Function to get the image path based on group type
  const getImagePath = (groupType) => {
    switch (groupType) {
      case 'Business':
        return '/images/Business.svg';
      case 'Church':
        return '/images/Hospital.svg';
      case 'Extended Family':
        return '/images/Family.svg';
      case 'Friends':
        return '/images/Friend.svg';
      default:
        return '/images/Neighbor.svg';  // Default image if group type is not recognized
    }
  };

  if (!currentGroupData || currentGroupData.length === 0) {
    return (
      <div className="text-center text-gray-800">
        <div className='p-4'>
          <p className='font-bold h4'>Create Your First Group</p>
          <p>Welcome to Status Now, <span className='font-bold'>{user?.userFirstName}</span>!👋</p> {/* Safely access user data */}
          <p className='mt-2'>To get started, tap the <span className='text-success font-bold'>green</span> icon below to create your first group and invite the people who matter most to join you.</p>
          <Link className='rounded-pill btn text-success mt-4 text-5xl' to={'/create-group'}>
            <CgAddR />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      {currentGroupData.map((group) => (
        <ul key={group.groupID}>
          <Link to={`/linked/${group.groupId}`} className='mb-5'>
            <li className="relative flex justify-between gap-x-6 p-3 hover:bg-gray-50 sm:px-6 border-top border-light">
              <div className="flex min-w-0 gap-x-4">
                <img
                  className=''
                  src={getImagePath(group.groupType)}  // Set image path dynamically based on group type
                  alt={group.groupType}
                  style={{ width: '50px', height: '50px', borderRadius: '15px' }}
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-md font-medium leading-6 text-gray-800">
                    {group.groupName}
                  </p>
                  <p className="mt-1 flex text-xs font-medium leading-5 text-gray-500">
                    Code: {group.groupCode}
                  </p>
                </div>
              </div>

              {/* Dynamic rendering based on group.alert and pendingMemberEmails */}
              <div className="flex shrink-0 items-center gap-x-4">
                {group.alert === 'Active' && (
                  <DocumentTextIcon className="h-7 w-7 text-gray-500" />
                )}
                {group.pendingMemberEmails.length > 0 && (
                  <div className="relative inline-flex">
                    <UserCircleIcon className="h-7 w-7 text-gray-500" />
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full transform translate-x-1/2 -translate-y-1/2">
                      {group.pendingMemberEmails.length}
                    </span>
                  </div>
                )}
                <ChevronRightIcon className="h-7 w-7 flex-none text-gray-400" aria-hidden="true" />
              </div>
            </li>
          </Link>
        </ul>
      ))}
    </div>
  );
};

export default AppendGroup;
