import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AppendGroup from '../components/appendGroup';
import HomeFAB from '../components/homepageFAB';
import AllGroups from '../components/allGroups';
import MemberGroups from '../components/Group Comps/GroupLinked';
import OffcanvasMenu from '../components/OffcanvasMenu';
import ProfileButton from '../components/ProfileButton';
import UserInfo from '../components/userInfo';
import { fetchAllUserInfo, fetchAllGroups } from '../Api/apiService';
import { FaPeopleRoof } from "react-icons/fa6";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import NotificationBadge from '../components/Notification Comps/NotificationBadge.js'
import { CgAddR } from "react-icons/cg";
import MapView from '../components/Group Comps/mapView';

const HomePage = ({ deferredPrompt }) => {
  const [activeTab, setActiveTab] = useState('myGroups');
  const [activeSubTab, setActiveSubTab] = useState('createdCircles');
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [linkedGroupData, setLinkedGroupData] = useState([]);
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [archivedNotifications, setArchivedNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  // UseEffect to fetch data and request notification permission
  useEffect(() => {
    const fetchDataAndRequestPermission = async () => {
      if (currentUserEmail) {
        try {
          // Request Notification permission
          const permission = await window.Notification.requestPermission();
          if (permission === 'granted') {
            console.log('Notification permission granted.');
          } else {
            console.error('Notification permission denied.');
          }

          // Fetch user and group data
          const userInfo = await fetchAllUserInfo();
          const currentUser = userInfo.find(user => user.userEmail === currentUserEmail);
          setUserData(currentUser);
          localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(currentUser));

          const groupsData = await fetchAllGroups();
          const managedGroups = groupsData.filter(group => group.adminEmails.includes(currentUserEmail));
          const joinedGroups = groupsData.filter(group => group.memberEmails.includes(currentUserEmail) && !group.adminEmails.includes(currentUserEmail));
          setGroupData(managedGroups);
          setLinkedGroupData(joinedGroups);

          localStorage.setItem(`groupData-${currentUserEmail}`, JSON.stringify(managedGroups));
          localStorage.setItem(`linkedGroupData-${currentUserEmail}`, JSON.stringify(joinedGroups));

          const userProfileData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
          setUserProfile(userProfileData);

          const savedArchivedNotifications = JSON.parse(localStorage.getItem('archivedNotifications')) || [];
          setArchivedNotifications(savedArchivedNotifications);

          const savedNotifications = JSON.parse(localStorage.getItem('notifications')) || [];
          setNotifications(savedNotifications);
          setNotificationCount(savedNotifications.length);
        } catch (error) {
          console.error('Error fetching data or requesting permission:', error);
        }
      }
      setDataLoaded(true);
    };

    fetchDataAndRequestPermission();
  }, [currentUserEmail]);

  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
    localStorage.setItem('archivedNotifications', JSON.stringify(archivedNotifications));
    setNotificationCount(notifications.length);
  }, [notifications, archivedNotifications]);

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!dataLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center bg-white" style={{ minHeight: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className='mt-5 h-screen'>
        <div className='border-b-2 mobilehide'>
          <ul className="d-flex justify-content-between pb-4 ">
          <nav className="d-flex navbar ms-4">
            <img className=" iconslide" src="/images-sitprep/List.png" width="50" alt="" />
            <img className="mx-1  iconslide" src="/images-sitprep/3-4 cross.png" width="75" alt="" />
            <img className=" " src="/images-sitprep/Sitprep Name.png" width="300" alt="" />

            </nav>
            <li className="mt-5 ">
              <ProfileButton userProfile={userProfile} toggleOffcanvas={toggleOffcanvas} />
              <OffcanvasMenu
                userProfile={userProfile}
                offcanvasOpen={offcanvasOpen}
                toggleOffcanvas={toggleOffcanvas}
                handleSignOut={handleSignOut}
              />
            </li>
           
          </ul>
        </div>

        <section className="rounded mt-3 container padding-main">
          <div className="ms-2 mobileshow">
            <img src='/images/SitPrep Logo new orangered – 3.png' alt='Status Now Icon – dark' width='250' height='250' />
          </div>

          <UserInfo currentUserEmail={currentUserEmail} />
          <div>
            <main className='p-2 rounded'>
              {/* Carousel can go here */}
            </main>
          </div>

          {activeTab === 'myGroups' && (
            <section>
              <ul className="nav nav-pills justify-content-center rounded-pill">
                <li className="nav-item">
                  <button
                    className={`rounded-pill mx-2 py-2 px-3 text-2xl ${activeSubTab === 'createdCircles' ? 'bg-black text-white fw-bold' : 'text-black'}`}
                    onClick={() => setActiveSubTab('createdCircles')}
                  >
                    <FaPeopleRoof />
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`rounded-pill mx-2 py-2 px-3 ${activeSubTab === 'joinedCircles' ? 'bg-black text-white fw-bold' : 'text-black'}`}
                    onClick={() => setActiveSubTab('joinedCircles')}
                  >
                    Joined
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`rounded-pill mx-2 py-2 px-3 ${activeSubTab === 'community' ? 'bg-black text-white fw-bold' : 'text-black'}`}
                    onClick={() => setActiveSubTab('community')}
                  >
                    Discover
                  </button>
                </li>
              </ul>

              {activeSubTab === 'createdCircles' && (
                <section className='mt-5 rounded-xl ring-1 ring-gray-900/5 bg-white'>
                  <div className=''>
                    <div className='d-flex justify-content-between me-3 '>
                      <p className='text-dark fw-semibold text-lg mx-4 mt-4'>My Circles</p>
                      <Link className='mobileshow text-success text-4xl my-3' to={{ pathname: '/create-group', state: { deferredPrompt } }}>
                        <CgAddR />
                      </Link>
                    </div>
                    <ul className="divide-y divide-gray-100 overflow-hidden pb-4 rounded-xl">
                      {userData && <AppendGroup groupData={groupData} currentUserEmail={currentUserEmail} />}
                    </ul>
                   
                  </div>
                </section>
              )}

                {activeSubTab === 'joinedCircles' && (
                  <section className='pt-3 mt-4'>
                    <div className='bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                      <p className='text-dark pb-3 fw-semibold text-lg mx-4 pt-4'>Associated Circles</p>
                      <ul className="divide-y divide-gray-100 overflow-hidden">
                        <MemberGroups linkedGroupData={linkedGroupData} handleHomePageButton={() => setActiveTab('community')} />
                      </ul>
                    </div>
                  </section>
                )}


              {activeSubTab === 'community' && (
                <section className='pt-3 mt-4'>
                  <div className='mt-3 bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                    <p className='text-dark pb-3 fw-semibold text-lg mx-4 pt-4'>Other Groups</p>
                    <AllGroups currentUserEmail={currentUserEmail} deferredPrompt={deferredPrompt} initialSearchQuery={searchQuery} />
                  </div>
                </section>
              )}
            </section>
          )}
        </section>
        <div className='mobilehide'>
          <HomeFAB currentUserEmail={currentUserEmail} deferredPrompt={deferredPrompt} />
        </div>
      </main>
      <footer className='mobileshow border-top fixed-bottom navbar bg-white pb-2'>
        <ul className="mb-1 grid grid-cols-6 grid-rows-1">
          <li className="mx-4 col-span-5 flex justify-evenly">
            {/* Notification Badge can go here */}
          </li>
          <li className="justify-self-end">
            <ProfileButton userProfile={userProfile} toggleOffcanvas={toggleOffcanvas} width="40px" height="40px" fontSize='20px' fontWeight="bold" />
            <OffcanvasMenu userProfile={userProfile} offcanvasOpen={offcanvasOpen} toggleOffcanvas={toggleOffcanvas} handleSignOut={handleSignOut} />
          </li>
        </ul>
      </footer>
    </>
  );
};

export default HomePage;
