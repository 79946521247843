import React, { useEffect, useState } from 'react';
import { IoMdMore } from "react-icons/io";
import { Link } from 'react-router-dom';
import { fetchGroupById } from '../Api/apiService.js';
import GroupImage from './UI Comps/GroupImage.js';

const SubGroup = ({ groupId }) => {
  const [groupData, setGroupData] = useState(null); // State to store fetched data

  useEffect(() => {
    // Fetch the group data when the component mounts
    const fetchData = async () => {
      try {
        const data = await fetchGroupById(groupId);
        setGroupData(data); // Store the data in state
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    };

    fetchData();
  }, [groupId]);

  if (!groupData) {
    return <div>Loading...</div>; // Show loading state while data is being fetched
  }

  // Render the subgroup with the name and owner's name
  return (
    <div className="container mt-3">
      <div className="card border">
        <div className="card-body d-flex">
          <div>
            <div>
              <div>
                <div
                  className="rounded-circle border p-1 d-flex align-items-center justify-content-center"
                  style={{
                    width: '60px',
                    height: '60px',
                    backgroundColor: 'gray', // You can customize the background color
                    borderRadius: '50%',
                  }}
                >
                 <GroupImage group={groupData}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 pl-3">
            <div className="font-weight-bold text-dark">{groupData.groupName}</div>
            <div className="text-secondary">Owner: {groupData.ownerName}</div>
          </div>
          <div>
            <Link to={`/Group/${groupId}`} className="text-decoration-none text-dark">
              <IoMdMore />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubGroup;
