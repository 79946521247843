import React, { useState, useEffect, useRef } from 'react';
import { FaUsers, FaSearch, FaEllipsisV } from 'react-icons/fa';
import { Menu } from '@headlessui/react';
import { fetchUserProfileByEmail, updateGroup, fetchAllGroups, updateUser } from '../Api/apiService';

const Members = ({ memberEmails, pendingMemberEmails, currentUserEmail, groupId, groupOwner, isAnAdmin, groupData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [memberProfiles, setMemberProfiles] = useState([]);
  const [pendingProfiles, setPendingProfiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(1);
  const pageSize = 10;

  // Determine if current user is the owner or an admin
  const isOwner = currentUserEmail === groupOwner;
  const isAdmin = isAnAdmin.includes(currentUserEmail);

  // Clear success and error messages after 3 seconds
  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  // Fetch member profiles with pagination
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profiles = await Promise.all(memberEmails.slice(0, page * pageSize).map(email => fetchUserProfileByEmail(email)));
        setMemberProfiles(profiles);
      } catch (error) {
        setErrorMessage('Error fetching member profiles.');
      }
    };
    fetchProfiles();
  }, [memberEmails, page]);

  // Fetch pending member profiles (only for owner/admin)
  useEffect(() => {
    if (isOwner || isAdmin) {
      const fetchPendingProfiles = async () => {
        try {
          const profiles = await Promise.all(pendingMemberEmails.map(email => fetchUserProfileByEmail(email)));
          setPendingProfiles(profiles);
        } catch (error) {
          setErrorMessage('Error fetching pending profiles.');
        }
      };
      fetchPendingProfiles();
    }
  }, [pendingMemberEmails, isOwner, isAdmin]);

  const handleApproveMember = async (email) => {
    try {
      const updatedGroup = {
        ...groupData,
        memberEmails: [...new Set([...groupData.memberEmails, email])],
        pendingMemberEmails: groupData.pendingMemberEmails.filter(pendingEmail => pendingEmail !== email),
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(prevProfiles => [...prevProfiles, pendingProfiles.find(profile => profile.userEmail === email)]);
      setPendingProfiles(prevProfiles => prevProfiles.filter(profile => profile.userEmail !== email));
      setSuccessMessage(`${email} has been approved as a member.`);
    } catch (error) {
      setErrorMessage('Failed to approve the member. Please try again.');
    }
  };

  const handleRejectMember = async (email) => {
    try {
      const updatedGroup = {
        ...groupData,
        pendingMemberEmails: groupData.pendingMemberEmails.filter(pendingEmail => pendingEmail !== email),
      };
      await updateGroup(groupId, updatedGroup);
      setPendingProfiles(prevProfiles => prevProfiles.filter(profile => profile.userEmail !== email));
      setSuccessMessage(`${email} has been rejected.`);
    } catch (error) {
      setErrorMessage('Failed to reject the member. Please try again.');
      console.error('Error rejecting member:', error);
    }
  };

  const handleMakeOwner = async (email) => {
    if (!isOwner) {
      setErrorMessage('Only the owner can transfer ownership.');
      return;
    }
    try {
      const updatedGroup = {
        ...groupData,
        ownerEmail: email,
        adminEmails: [...new Set([...groupData.adminEmails, email])],
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(prevProfiles => 
        prevProfiles.map(profile => profile.userEmail === email ? { ...profile, isOwner: true } : profile)
      );
      setSuccessMessage(`${email} is now the owner.`);
    } catch (error) {
      setErrorMessage('Failed to transfer ownership.');
    }
  };

  const handleAddAsAdmin = async (email) => {
    try {
      const updatedGroup = {
        ...groupData,
        adminEmails: [...new Set([...groupData.adminEmails, email])],
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(prevProfiles => prevProfiles.map(profile => profile.userEmail === email ? { ...profile, isAdmin: true } : profile));
      setSuccessMessage(`${email} has been added as an admin.`);
    } catch (error) {
      setErrorMessage('Error adding admin.');
    }
  };

  const handleRemoveAsAdmin = async (email) => {
    try {
      const updatedGroup = {
        ...groupData,
        adminEmails: groupData.adminEmails.filter(adminEmail => adminEmail !== email),
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(prevProfiles => prevProfiles.map(profile => profile.userEmail === email ? { ...profile, isAdmin: false } : profile));
      setSuccessMessage(`${email} has been removed as an admin.`);
    } catch (error) {
      setErrorMessage('Error removing admin.');
    }
  };

  const handleRemoveMember = async (email) => {
    try {
      const updatedGroup = {
        ...groupData,
        memberEmails: groupData.memberEmails.filter(memberEmail => memberEmail !== email),
        adminEmails: groupData.adminEmails.filter(adminEmail => adminEmail !== email),
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(prevProfiles => prevProfiles.filter(profile => profile.userEmail !== email));
      setSuccessMessage(`${email} has been removed from the group.`);
    } catch (error) {
      setErrorMessage('Error removing member.');
    }
  };

  // Load more data when "Load More" is clicked
  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  // Filter and sort members alphabetically by first and last name
  const filteredMembers = [
    ...pendingProfiles.map(profile => ({ ...profile, isPending: true })),
    ...memberProfiles.map(profile => ({ ...profile, isPending: false })),
  ]
    .filter(profile =>
      profile.userEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (profile.userFirstName && profile.userFirstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (profile.userLastName && profile.userLastName.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      const fullNameA = `${a.userFirstName} ${a.userLastName}`.toLowerCase();
      const fullNameB = `${b.userFirstName} ${b.userLastName}`.toLowerCase();
      return fullNameA.localeCompare(fullNameB);
    });

  // Function to determine if email should be visible
  const canViewEmail = (profile) => {
    if (isOwner || isAdmin) {
      // Owners and admins can view everyone's emails
      return true;
    }
    // Members can only see admin and owner emails
    return profile.userEmail === groupOwner || isAnAdmin.includes(profile.userEmail);
  };

  return (
    <div className="lg:rounded-b-lg border-black-500 p-2 px-4 bg-white border-bottom pt-4 pb-5 rounded">
      {/* Success and Error Messages */}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}

      <div className="flex items-center justify-between py-3">
        <div className="flex items-center">
          <FaUsers className="text-secondary mr-2" />
          <p className="text-secondary text-lg font-semibold ">{memberProfiles.length + pendingProfiles.length} Member(s)</p>
        </div>
      </div>

      <div className="mb-4">
        <div className="flex items-center mb-3">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Search members..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </div>

      {/* Members List */}
      <ul className="space-y-5 max-h-100 mt-4 mb-5">
        {/* Pending Members */}
        {(isOwner || isAdmin) && (
          <>
            <li className="text-gray-500 text-sm font-bold">Pending Members</li>
            {filteredMembers
              .filter((profile) => profile.isPending)
              .map((profile, index) => (
                <li key={index} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full mr-3 flex items-center justify-center overflow-hidden bg-gray-400 text-white font-semibold">
                      {profile.profileImageURL ? (
                        <img src={profile.profileImageURL} alt={profile.userEmail} className="h-full w-full object-cover" />
                      ) : (
                        <span>{`${profile.userFirstName.charAt(0)}${profile.userLastName.charAt(0)}`}</span>
                      )}
                    </div>
                    <div>
                      <p className="text-gray-700 font-semibold">{profile.userFirstName} {profile.userLastName}</p>
                      <p className="text-gray-500 text-sm">{canViewEmail(profile) ? profile.userEmail : 'Email Hidden'}</p>
                    </div>
                  </div>
                  <div className="flex space-x-2 mx-2">
                    <button className="bg-green-500 text-white px-2 py-1 rounded" onClick={() => handleApproveMember(profile.userEmail)}>Accept</button>
                    <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleRejectMember(profile.userEmail)}>Decline</button>
                  </div>
                </li>
              ))}
            <li className="border-b my-4"></li>
          </>
        )}

        {/* Current Members */}
        <li className="text-gray-500 text-sm font-bold">Current Members</li>
        {filteredMembers
          .filter((profile) => !profile.isPending)
          .map((profile, index) => (
            <li key={index} className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full mr-3 flex items-center justify-center overflow-hidden bg-gray-400 text-white font-semibold">
                  {profile.profileImageURL ? (
                    <img src={profile.profileImageURL} alt={profile.userEmail} className="h-full w-full object-cover" />
                  ) : (
                    <span>{`${profile.userFirstName.charAt(0)}${profile.userLastName.charAt(0)}`}</span>
                  )}
                </div>
                <div>
                  <p className="text-gray-700 font-semibold">{profile.userFirstName} {profile.userLastName}</p>
                  <p className="text-gray-500 text-sm">{canViewEmail(profile) ? profile.userEmail : 'Member'}</p>
                  {profile.userEmail === groupOwner ? (
                    <p className="text-gray-500 text-sm">Owner</p>
                  ) : isAnAdmin.includes(profile.userEmail) ? (
                    <p className="text-gray-500 text-sm">Admin</p>
                  ) : null}
                </div>
              </div>
              {/* Ellipsis Menu should NOT be shown for the current user's profile */}
              {(isOwner || isAdmin) && profile.userEmail !== currentUserEmail && profile.userEmail !== groupData.ownerEmail && (
                <div className="relative">
                  {/* Ellipsis Button */}
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button as="button" className="text-gray-500 hover:text-gray-700 focus:outline-none">
                      <FaEllipsisV />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 bg-white shadow-lg rounded-md">
                      {isAnAdmin.includes(profile.userEmail) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? 'bg-gray-100' : ''} w-full text-left px-4 py-2 text-sm`}
                              onClick={() => handleRemoveAsAdmin(profile.userEmail)}
                            >
                              Remove as Admin
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      {!isAnAdmin.includes(profile.userEmail) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? 'bg-gray-100' : ''} w-full text-left px-4 py-2 text-sm`}
                              onClick={() => handleAddAsAdmin(profile.userEmail)}
                            >
                              Add as Admin
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      {isOwner && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? 'bg-gray-100' : ''} w-full text-left px-4 py-2 text-sm`}
                              onClick={() => handleMakeOwner(profile.userEmail)}
                            >
                              Make Owner
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-red-100' : ''} w-full text-left px-4 py-2 text-sm text-red-500`}
                            onClick={() => handleRemoveMember(profile.userEmail)}
                          >
                            Remove Member
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </div>
              )}
            </li>
          ))}
      </ul>

      {/* Pagination / Load More */}
      {/* <div className="mt-4 text-center">
        <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={loadMore}>
          Load More
        </button>
      </div> */}
    </div>
  );
};

export default Members;
