import React, { useState, useEffect, useRef } from 'react';
import {fetchCommentsByPostId, createPost, fetchPostsByGroupId, updatePost, deletePost, fetchUserProfileByEmail, fetchGroupById, updateReactions } from '../Api/apiService';
import Comments from './PostComments';
import { FaEllipsisV, FaImage, FaRegCommentDots } from 'react-icons/fa';
import { getLinkPreview } from 'link-preview-js';

// Define possible emojis for reactions
const emojis = [
  { name: 'Like', emoji: '👍' },
  { name: 'Love', emoji: '❤️' },
  { name: 'Dislike', emoji: '👎' },
  { name: 'Shocked', emoji: '😱' },
  { name: 'Excited', emoji: '😆' },
  { name: 'Sad', emoji: '😢' },
  { name: 'Angry', emoji: '😡' },
  { name: 'Laugh', emoji: '😂' },
  { name: 'Clap', emoji: '👏' },
  { name: 'Star', emoji: '⭐' },
];

// Utility function to detect YouTube links
const detectYouTubeLink = (content) => {
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = content.match(youtubeRegex);
  return match ? `https://www.youtube.com/embed/${match[1]}` : null;
};

// Utility function to detect Vimeo links
const detectVimeoLink = (content) => {
  const vimeoRegex = /https?:\/\/(www\.)?vimeo.com\/(\d+)(?:\/\S*)?/;
  const match = content.match(vimeoRegex);
  return match ? `https://player.vimeo.com/video/${match[2]}` : null;
};

// Utility function to detect other website links and create clickable hyperlinks
const detectWebsiteLink = (content) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const match = content.match(urlRegex);
  return match ? match[0] : null;
};

const Posts = ({ groupId, currentUserEmail }) => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [editPostId, setEditPostId] = useState(null);
  const [editPostContent, setEditPostContent] = useState('');
  const [tags, setTags] = useState(''); 
  const [showOptions, setShowOptions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // New state for image preview
  const [authorProfiles, setAuthorProfiles] = useState({});
  const [groupName, setGroupName] = useState('oldest');
  const [charCount, setCharCount] = useState(0); 
  const charLimit = 300; 
  const optionsRef = useRef(null);
  const [linkPreviewData, setLinkPreviewData] = useState({});

 
  const [commentsVisible, setCommentsVisible] = useState({});
  const [commentsCount, setCommentsCount] = useState({});

  const userData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
  const userProfileImage = userData?.profileImageURL;
  const userInitials = userData ? `${userData.userFirstName.charAt(0)}${userData.userLastName.charAt(0)}` : '';

  

  useEffect(() => {
    // Fetch posts and initialize comments count for each post
    const fetchPostsAndComments = async () => {
      try {
        const fetchedPosts = await fetchPostsByGroupId(groupId);
        setPosts(fetchedPosts);

        // Fetch comment counts for each post
        const countData = {};
        for (let post of fetchedPosts) {
          const comments = await fetchCommentsByPostId(post.id);
          countData[post.id] = comments.length;
        }
        setCommentsCount(countData);
      } catch (error) {
        console.error('Error fetching posts or comment counts:', error);
      }
    };

    fetchPostsAndComments();
  }, [groupId]);

  const toggleCommentsVisibility = (postId) => {
    setCommentsVisible(prevState => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };




  // Fetch group and posts data when component mounts or groupId changes
  useEffect(() => {
    const fetchGroupAndPosts = async () => {
      try {
        const group = await fetchGroupById(groupId);
        setGroupName(group.groupName);

        const posts = await fetchPostsByGroupId(groupId);
        setPosts(posts);

        const profiles = await Promise.all(posts.map(post => fetchUserProfileByEmail(post.author)));
        const profilesMap = profiles.reduce((acc, profile) => {
          acc[profile.userEmail] = profile;
          return acc;
        }, {});
        setAuthorProfiles(profilesMap);
      } catch (error) {
        console.error('Error fetching group and posts:', error);
      }
    };

    fetchGroupAndPosts();
  }, [groupId]);

  // Handle image file upload and preview
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      alert('File is too large. Maximum size is 5MB.');
      return;
    }

    setSelectedImage(file);
    setImagePreview(URL.createObjectURL(file)); // Set the preview image
  };

  // Handle post creation or update
  const handleCreateOrUpdatePost = async () => {
    const contentToSave = isEditing ? editPostContent : newPost;
    if (contentToSave.trim() === '' || charCount > charLimit) return; 

    const formData = new FormData();
    formData.append('author', currentUserEmail);
    formData.append('content', contentToSave);
    formData.append('groupId', groupId);
    formData.append('groupName', groupName);

    if (selectedImage) {
      formData.append('imageFile', selectedImage);
    }

    formData.append('tags', tags);

    try {
      if (isEditing) {
        await updatePost(editPostId, formData);
        setPosts(posts.map(post => (post.id === editPostId ? { ...post, content: contentToSave, base64Image: imagePreview } : post)));
      } else {
        const createdPost = await createPost(formData);
        setPosts([{ ...createdPost, base64Image: imagePreview }, ...posts]);
      }

      resetPostForm();
    } catch (error) {
      console.error('Error creating or updating post:', error);
    }
  };

  // Reset form after post creation
  const resetPostForm = () => {
    setNewPost('');
    setEditPostContent('');
    setSelectedImage(null);
    setImagePreview(null); // Reset the image preview
    setTags('');
    setIsEditing(false);
    setCharCount(0); 
    setShowModal(false);
  };

  // Handle character count and update the post content
  const handlePostContentChange = (e) => {
    const content = e.target.value;
    setCharCount(content.length);
    if (isEditing) {
      setEditPostContent(content);
    } else {
      setNewPost(content);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionsRef.current && !optionsRef.current.contains(e.target)) {
        setShowOptions(null); // Close the options dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  // Handle editing a post
  const handleEditPost = (post) => {
    setIsEditing(true);
    setEditPostId(post.id);
    setEditPostContent(post.content);
    setTags(post.tags?.join(', ') || '');
    setImagePreview(post.base64Image); // Show existing image in the preview
    setShowModal(true);
  };

  // Handle deleting a post
  const handleDeletePost = async (postId) => {
    try {
      await deletePost(postId);
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  // Handle reactions
  const handleReaction = async (postId, reactionType) => {
    try {
      const updatedReactions = await updateReactions(postId, reactionType);
      setPosts(posts.map(post => (post.id === postId ? { ...post, reactions: updatedReactions } : post)));
    } catch (error) {
      console.error('Error adding reaction:', error);
    }
  };

  

  return (
    <div className="flow-root space-y-6">
      <div className="p-4 bg-white rounded-lg shadow-sm mt-3">
        <div className="flex items-center space-x-2">
          <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
            {userProfileImage ? (
              <img src={userProfileImage} alt="Profile" className="w-full h-full object-cover" />
            ) : (
              <span className="text-gray-500">{userInitials}</span>
            )}
          </div>
          <button
            className="w-full p-2 rounded-full bg-gray-200 text-left text-gray-600"
            onClick={() => {
              setShowModal(true);
              setIsEditing(false);
            }}
          >
            What's on your mind, {userData.userFirstName}?
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-sm p-6 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">{isEditing ? 'Edit Post' : 'Create Post'}</h2>
            <textarea
              className={`w-full p-2 border border-gray-300 rounded mb-4 resize-none focus:outline-none ${charCount > charLimit ? 'border-red-500' : ''}`}
              placeholder="What's on your mind?"
              value={isEditing ? editPostContent : newPost}
              onChange={handlePostContentChange} 
              maxLength={charLimit}
              style={{ height: 'auto' }}
            />
            <div className="text-right text-gray-500">{charCount}/{charLimit}</div>

            {/* Image Upload */}
            <div className="flex space-x-2 mb-4">
              <input type="file" accept="image/*" onChange={handleImageUpload} className="hidden" id="upload-image" />
              <label htmlFor="upload-image" className="cursor-pointer">
                <FaImage className="text-gray-500 hover:text-gray-700" />
              </label>
            </div>

            {/* Image Preview */}
            {imagePreview && (
              <div className="relative mb-4 inline-block">
                <img 
                  src={imagePreview} 
                  alt="Selected" 
                  className="max-w-full max-h-48 object-cover rounded-lg" 
                />
                <button 
                  onClick={() => {
                    setSelectedImage(null);
                    setImagePreview(null);
                  }} 
                  className="absolute top-1 right-1 bg-gray-700 text-white rounded-full p-1 hover:bg-gray-500"
                >
                  ✕
                </button>
              </div>
            )}

            {/* Tags Input */}
            <input
              type="text"
              placeholder="Add tags (comma separated)"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />

            <div className="flex space-x-2">
              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded-pill hover:bg-blue-600 ${charCount > charLimit ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleCreateOrUpdatePost}
                disabled={charCount > charLimit}
              >
                {isEditing ? 'Save' : 'Post'}
              </button>
              <button className="bg-gray-500 text-white px-4 py-2 rounded-pill hover:bg-gray-600" onClick={resetPostForm}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Displaying Posts */}
      {posts.map((post) => {
        const authorProfile = authorProfiles[post.author];
        const authorProfileImage = authorProfile?.profileImageURL;
        const authorInitials = authorProfile ? `${authorProfile.userFirstName.charAt(0)}${authorProfile.userLastName.charAt(0)}` : '';
        const authorName = authorProfile ? `${authorProfile.userFirstName} ${authorProfile.userLastName}` : post.author;

        const youtubeLink = detectYouTubeLink(post.content);
        const vimeoLink = detectVimeoLink(post.content);
        const websiteLink = detectWebsiteLink(post.content);

        return (
          <div key={post.id} className="relative pb-8 bg-white rounded shadow-sm">
            <div className="px-3 pt-4 rounded-lg shadow-sm-md flex">
              <div className="">
                <div className="flex relative ">
                  <div className="h-10 w-10 rounded-full bg-gray-600 ring-8 ring-white flex items-center justify-center overflow-hidden">
                    {authorProfileImage ? (
                      <img className="h-full w-full object-cover" src={authorProfileImage} alt={`${authorName}'s profile`} />
                    ) : (
                      <span className="text-white font-semibold">{authorInitials}</span>
                    )}
                  </div>
                  <div className="ps-2 text-sm">
                    <a href="#" className="font-medium text-gray-900">
                      {authorName}
                    </a>
                    {/* Display date and time in user's time zone */}
                    <p className="mt-0.5 text-xs text-gray-500">
                      {new Date(post.timestamp).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' })}
                    </p>
                  </div>
                </div>
                <section className="word-break break-all overflow-wrap break-word">
                  <p className=" mt-3 ps-2 text-sm">{post.content}</p>
                </section>


              </div>
              
              {post.author === currentUserEmail && (
                    <div className="ml-auto mt-2 text-sm text-gray-700">
                      <button onClick={() => setShowOptions(post.id === showOptions ? null : post.id)} className="text-gray-500 hover:text-gray-700 focus:outline-none">
                        <FaEllipsisV />
                      </button>
                      {post.id === showOptions && (
                        <div ref={optionsRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-sm-sm z-10">
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleEditPost(post)}>
                            Edit
                          </button>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleDeletePost(post.id)}>
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  )}

            </div>
          <section>
                {/* Embed YouTube Video */}
                {youtubeLink && (
                  <div className="mt-3 ">
                    <iframe
                      width="100%"
                      height="315"
                      src={youtubeLink}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full"
                    ></iframe>
                  </div>
                )}

                {/* Embed Vimeo Video */}
                {vimeoLink && (
                  <div className="mt-3 ">
                    <iframe
                      width="100%"
                      height="315"
                      src={vimeoLink}
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      className="w-full h-auto aspect-video"
                    ></iframe>
                  </div>
                )}

                {/* Display hyperlink if it's not a video */}
                {websiteLink && !youtubeLink && !vimeoLink && (
                  <div className="mt-3 px-4">
                    <a href={websiteLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline block w-full word-break break-all overflow-wrap break-word">
                      {websiteLink}
                    </a>
                  </div>
                )}

                {/* Displaying Image Below Post */}
                {post.base64Image && (
                  <div className="mt-3 ">
                    <img src={post.base64Image} alt="Post" className="w-100 h-auto" />
                  </div>
                )}
        </section>
        
            {/* Reactions Section */}
            {/* Show/Hide Comments Button */}
          <div className="d-flex mt-2 border-t py-2 px-4 justify-content-between">
            <div className='flex mt-3 text-gray-500'>
            <FaRegCommentDots className="mr-2 text-lg" />
            <button
              className=" font-semibold text-sm"
              onClick={() => toggleCommentsVisibility(post.id)}
            >
               
               {commentsVisible[post.id] ? "Post Comment" : "Post Comment"} 
              
        
            </button>
            </div>
            
            <button
              className="mt-3 text-blue-500 font-semibold text-sm"
              onClick={() => toggleCommentsVisibility(post.id)}
            >
             {commentsVisible[post.id] ? "Hide Comments" : "Show Comments"} 
             ({commentsCount[post.id] || 0})
           
            </button>
            
           
          </div>
          <div className="mx-3">
              {/* Conditionally render Comments component based on visibility */}
              {commentsVisible[post.id] && (
                    <Comments
                      postId={post.id}
                      currentUserEmail={currentUserEmail}
                      
                    />
                  )}
          </div>
          </div>
        );
      })}
    </div>
  );
};

export default Posts;
