import React from 'react';
import { Link, useParams } from 'react-router-dom';
import GroupPageStatusCardWidget from '../components/GroupPageStatusCard.js'
import HomeFAB from '../components/homepageFAB.js';


const GetStatus = () => {
  const { groupId } = useParams(); // Get the groupId from the URL params
  
  return (
    <main>
      <div className="align-self-center ml-auto pb-3 d-flex">
        <Link className='btn btn-primary' to={`/group/${groupId}`} variant="primary">&#8594;</Link>
        <h2>My Group Status</h2>
      </div>
      
      <div >
        
        {/* Pass the groupId to GroupPageStatusCardWidget */}
        <GroupPageStatusCardWidget groupId={groupId} />
      </div>
      <div className='pt-3'>
        {/* Other content */}
      </div>
      <HomeFAB/>
    </main>
  );
}

export default GetStatus;






