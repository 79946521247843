import React from 'react';
import Navbar from '../Navbar';
import Dashboard from '../Dashboard';
import '../sitprep.css';

const SitPrepHome = () => {
  return (
    <div className="sitprep-app">
      <Navbar />
      <Dashboard />
    </div>
  );
};

export default SitPrepHome;
