import React from 'react';

const ProfileButton = ({ userProfile, toggleOffcanvas, width = '55px', height = '55px', fontSize = '24px',
fontWeight ='bold' }) => {
  return (
    <button
      className="ms-3 me-4  shadow-sm"
      type="button"
      onClick={toggleOffcanvas}
      style={{
        backgroundImage: `url(${
          userProfile && userProfile.profileImageURL
            ? userProfile.profileImageURL
            : ''
        })`,
        backgroundColor: `${
          userProfile && userProfile.profileImageURL
            ? 'transparent'
            : ' #000000' // black background for initials if no profile image
        }`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: width,
        height: height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: '#ffffff', // White color for initials
        textTransform: 'uppercase',
        borderRadius: '100%',
        border: '2px solid',
        borderColor: 'white',
      }}
    >
      {userProfile && userProfile.profileImageURL
        ? '' // If there's a profile image URL, don't display initials
        : `${(userProfile && userProfile.userFirstName.charAt(0)) || ''}${(userProfile && userProfile.userLastName.charAt(0)) || ''}`
      }
    </button>
  );
};

export default ProfileButton;
