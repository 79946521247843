import React, { useState, useEffect, useCallback } from 'react';
import { IoSearch, IoAddCircleOutline } from 'react-icons/io5';
import { fetchAllGroups, fetchGroupById, updateGroup } from '../../Api/apiService';
import debounce from 'lodash.debounce';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import GroupImage from '../UI Comps/GroupImage';

const AddSubGroup = ({ subgrGroupId }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [loadingParents, setLoadingParents] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [parentGroups, setParentGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmGroupId, setConfirmGroupId] = useState(null);

  // Automatically hide error and success messages after 3 seconds
  useEffect(() => {
    if (error || successMessage) {
      const timer = setTimeout(() => {
        setError('');
        setSuccessMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, successMessage]);

  // Fetch groups and current group information
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await fetchAllGroups();
        setAllGroups(groupsData.filter(group => group.groupId !== subgrGroupId));
      } catch (error) {
        console.error('Error fetching groups:', error);
        setError('Could not load groups. Please try again.');
      } finally {
        setLoadingGroups(false);
      }
    };
    fetchGroups();
  }, [subgrGroupId]);

  useEffect(() => {
    const fetchCurrentGroup = async () => {
      try {
        const group = await fetchGroupById(subgrGroupId);
        setCurrentGroup(group);
        if (group && group.parentGroupIDs) {
          const parentGroupPromises = group.parentGroupIDs.map((parentId) =>
            fetchGroupById(parentId)
          );
          const parentGroupsData = await Promise.all(parentGroupPromises);
          setParentGroups(parentGroupsData);
        }
      } catch (error) {
        console.error('Error fetching parent groups:', error);
        setError('Could not load parent groups. Please try again.');
      } finally {
        setLoadingParents(false);
      }
    };
    fetchCurrentGroup();
  }, [subgrGroupId]);

  const handleLeaveParentGroup = async (parentId) => {
    if (!window.confirm('Are you sure you want to leave this parent group?')) return;

    try {
      const parentGroup = await fetchGroupById(parentId);
      const updatedParentGroupData = {
        ...parentGroup,
        subGroupIDs: parentGroup.subGroupIDs.filter((id) => id !== subgrGroupId.toString()),
      };

      await updateGroup(parentId, updatedParentGroupData);

      const updatedCurrentGroupData = {
        ...currentGroup,
        parentGroupIDs: currentGroup.parentGroupIDs.filter((id) => id !== parentId.toString()),
      };

      await updateGroup(subgrGroupId, updatedCurrentGroupData);

      setParentGroups((prev) => prev.filter((group) => group.groupId !== parentId));
      setCurrentGroup(updatedCurrentGroupData);
      setSuccessMessage('Successfully left the parent group.');
    } catch (error) {
      console.error('Error leaving parent group:', error);
      setError('Could not leave parent group. Please try again.');
    }
  };

  const requestAddSubgroup = (groupId) => {
    setConfirmGroupId(groupId);
    setShowModal(true);
  };

  const confirmJoinGroup = async () => {
    if (!confirmGroupId) return;
    try {
      const selectedGroup = await fetchGroupById(confirmGroupId);
      const updatedSelectedGroupData = {
        ...selectedGroup,
        subGroupIDs: [...new Set([...selectedGroup.subGroupIDs, subgrGroupId])],
      };
      await updateGroup(confirmGroupId, updatedSelectedGroupData);
  
      const updatedCurrentGroupData = {
        ...currentGroup,
        parentGroupIDs: [...new Set([...currentGroup.parentGroupIDs, confirmGroupId])],
      };
      await updateGroup(subgrGroupId, updatedCurrentGroupData);
  
      const refreshedCurrentGroup = await fetchGroupById(subgrGroupId);
      setCurrentGroup(refreshedCurrentGroup); 
      const newParentGroup = await fetchGroupById(confirmGroupId);
      setParentGroups(prev => [...prev, newParentGroup]);
  
      setSuccessMessage('Successfully added as subgroup!');
      setConfirmGroupId(null);
      setShowModal(false);
    } catch (error) {
      console.error('Error adding subgroup:', error);
      setError('Could not add subgroup. Please try again.');
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => setSearchQuery(query), 300),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const filteredGroups = allGroups.filter(group => {
    const query = searchQuery.toLowerCase();
    return (
      group.groupName.toLowerCase().includes(query) ||
      group.ownerName.toLowerCase().includes(query) ||
      group.description.toLowerCase().includes(query) ||
      group.groupId.toString().includes(query)
    );
  });

  const closeModal = () => {
    setShowModal(false);
    setConfirmGroupId(null);
    setSearchQuery('');
  };

  return (
    <div className='container '>
      {/* Parent Groups Section */}
      <div className="flex justify-between items-center ">
        <h3 className="text-xl font-semibold">Parent Groups</h3>
        <button onClick={() => setShowModal(true)} className="flex items-center gap-2 text-blue-600 hover:text-blue-800">
          <IoAddCircleOutline className="h-5 w-5" />
          <span>Join a Parent Group</span>
        </button>
      </div>

      {loadingParents ? (
        <p className="text-gray-600 text-center py-3">Loading parent groups...</p>
      ) : parentGroups.length > 0 ? (
        <ul role="list" className="divide-y divide-gray-100">
          {parentGroups.map((group) => (
            <li key={group.groupId} className="flex items-center justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
              <GroupImage group={group}/>
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold text-gray-900">{group.groupName}</p>
                  <p className="text-xs text-gray-500"><strong>Owner: </strong>{group.ownerName}</p>
                  <p className="text-xs text-gray-500 truncate"><strong>Purpose: </strong>{group.description}</p>
                </div>
              </div>
              <button
                onClick={() => handleLeaveParentGroup(group.groupId)}
                className="badge rounded-full bg-red-400 px-2.5 py-1 text-xs font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-500"
              >
                Leave Group
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-gray-600 text-center py-3">
          <p>This group is not currently part of any parent groups.</p>
          <button
            onClick={() => setShowModal(true)}
            className="mt-2 inline-flex items-center gap-1 text-blue-600 hover:text-blue-800 font-medium"
          >
            <IoAddCircleOutline className="h-5 w-5" />
            <span>Join a Parent Group</span>
          </button>
        </div>
      )}

      {/* Modal for Adding or Confirming Parent Group */}
      <Dialog open={showModal} onClose={closeModal} className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen p-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4 ">
              <button
                type="button"
                onClick={closeModal}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900 text-center">
              {confirmGroupId ? 'Confirm Join Parent Group' : 'Add a Parent Group'}
            </Dialog.Title>

            <div className="mt-4">
              {confirmGroupId ? (
                <div className="text-gray-700 text-sm">
                  <p>Joining this group will allow the parent group's admins to view your group's status and any associated address.</p>
                  <p className="mt-2">Are you sure you want to join this parent group?</p>
                  <button
                    onClick={confirmJoinGroup}
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded mt-3"
                  >
                    Yes, Join Parent Group
                  </button>
                </div>
              ) : (
                <>
                  <div className="flex items-center mb-4 p-3 bg-white rounded">
                    <IoSearch className="text-gray-400 mr-2" />
                    <input
                      type="text"
                      placeholder="Search groups by name, owner, or ID"
                      className="flex-grow p-2 text-gray-600 border-none outline-none rounded bg-light"
                      onChange={handleSearchChange}
                    />
                  </div>
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                  {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}
                  {!loadingGroups && searchQuery ? (
                    filteredGroups.length > 0 ? (
                      <div className="grid grid-cols-1 gap-4">
                        {filteredGroups.map(group => (
                          <GroupCard
                            key={group.groupId}
                            group={group}
                            currentGroup={currentGroup}
                            onAddSubgroup={requestAddSubgroup}
                          />
                        ))}
                      </div>
                    ) : (
                      <p>No groups found matching your search.</p>
                    )
                  ) : (
                    searchQuery ? <p>Loading groups...</p> : <p>Enter a search to find groups.</p>
                  )}
                </>
              )}
            </div>

            <div className="mt-5 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={closeModal}
                className="inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-400 sm:w-auto"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

const GroupCard = ({ group, currentGroup, onAddSubgroup }) => {
  const isAlreadySubgroup = currentGroup ? currentGroup.parentGroupIDs.includes(group.groupId.toString()) : false;
  const isCurrentGroup = currentGroup ? currentGroup.groupId === group.groupId : false;

  const getImagePath = (groupType) => {
    switch (groupType) {
      case 'Business': return '/images/Business.svg';
      case 'Church': return '/images/Church.svg';
      case 'Extended Family': return '/images/Family.svg';
      case 'Friends': return '/images/Friends.svg';
      default: return '/images/Other.svg';
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg">
      <div className="flex items-center">
        <img src={getImagePath(group.groupType)} alt={group.groupType} className="w-12 h-12 mr-3" />
        <div>
          <p className="text-lg font-bold">{group.groupName}</p>
          <p className="text-sm text-gray-600">Owner: {group.ownerName}</p>
          <p className="text-sm text-gray-600">Privacy: {group.privacy}</p>
        </div>
      </div>
      <p className="mt-2 text-gray-700 text-sm">{group.description}</p>
      <button
        className={`mt-3 p-2 w-full text-white font-semibold rounded ${
          isAlreadySubgroup || isCurrentGroup ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
        }`}
        onClick={() => onAddSubgroup(group.groupId)}
        disabled={isAlreadySubgroup || isCurrentGroup}
      >
        {isAlreadySubgroup ? 'Already Joined' : isCurrentGroup ? 'Current Group' : 'Add as Parent Group'}
      </button>
    </div>
  );
};

export default AddSubGroup;
