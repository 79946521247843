'use client'

import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

const tiers = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '#',
    price: { monthly: '$0', annually: '$0' },
    description: 'Start preparing with essential features.',
    features: [
      'Single group creation',
      'Basic emergency plan templates',
      'Limited educational content',
      'Community forums (read-only)',
      'Basic notifications',
    ],
    mostPopular: false,
  },
  {
    name: 'Personal',
    id: 'tier-personal',
    href: '#',
    price: { monthly: '$4.99', annually: '$49.99' },
    description: 'Advanced tools for individuals and small families.',
    features: [
      'Up to 3 group creations',
      'Advanced emergency planning tools',
      'Geolocation tracking for group members',
      'Ad-free experience',
      'Priority notifications',
    ],
    mostPopular: false,
  },
  {
    name: 'Family',
    id: 'tier-family',
    href: '#',
    price: { monthly: '$7.99', annually: '$79.99' },
    description: 'Collaborative tools for families and groups.',
    features: [
      'Up to 10 group creations',
      'Family plan builder with collaboration',
      'Offline access to emergency plans',
      'Exclusive content and webinars',
      'Priority customer support',
    ],
    mostPopular: true,
  },
  {
    name: 'Professional',
    id: 'tier-professional',
    href: '#',
    price: { monthly: '$19.99', annually: '$199.99' },
    description: 'Comprehensive solutions for organizations.',
    features: [
      'Unlimited group creation',
      'Advanced analytics and management tools',
      'HR integration for safety tracking',
      'Customizable emergency templates',
      'Dedicated account support',
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function StatusReadyPricing() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base/7 font-semibold text-indigo-600">Pricing</h2>
          <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
            Plans for every level of preparedness
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
          Choose a plan that fits your needs and helps you prepare for the unexpected.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup value={frequency} onChange={setFrequency} className="flex gap-x-4">
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ active, checked }) =>
                  classNames(
                    'cursor-pointer rounded-full px-4 py-2 text-sm font-medium',
                    checked ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-900',
                    active ? 'ring-2 ring-indigo-600' : ''
                  )
                }
              >
                {option.label}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8',
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                  'text-lg/8 font-semibold',
                )}
              >
                {tier.name}
              </h3>
              <p className="mt-4 text-sm/6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-semibold tracking-tight text-gray-900">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm/6 font-semibold text-gray-600">{frequency.priceSuffix}</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-6 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                )}
              >
                Buy plan
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm/6 text-gray-600">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
