import React from 'react';
import NewMemberNotification from './NewMemberNotification';
import GroupAlertNotification from './GroupAlertNotification';

function NotificationList({ notifications, userFullName, clearNotification }) {
  return (
    <ul role="list" className="divide-y divide-gray-100">
      {notifications.length === 0 ? (
        <p className="text-sm text-gray-500 p-4">No notifications available.</p>
      ) : (
        notifications.map((notification) => (
          <li key={notification.id} className="flex items-center justify-between gap-x-6 py-3">
            {notification.type === 'newMember' && (
              <NewMemberNotification
                id={notification.id}
                currentAdmin={userFullName}
                newUser={notification.newUser}
                groupName={notification.groupName}
                clearNotification={clearNotification}
              />
            )}
            {notification.type === 'groupAlert' && (
              <GroupAlertNotification
                id={notification.id}
                userFullName={userFullName}
                groupAdminName={notification.groupAdminName}
                clearNotification={clearNotification}
              />
            )}
          </li>
        ))
      )}
    </ul>
  );
}

export default NotificationList;
