import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { HomeIcon, UserIcon } from '@heroicons/react/24/outline';
import { AiOutlineSafetyCertificate, AiOutlineFileText } from "react-icons/ai";


const OffcanvasMenu = ({ userProfile, offcanvasOpen, toggleOffcanvas }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleSignOutClick = async () => {
    try {
      await logout();
      navigate('/landing');
      localStorage.clear();
    } catch (error) {
      console.error('Error signing out:', error);
    } finally {
      toggleOffcanvas();
    }
  };

  const getUserInitials = () => {
    if (userProfile?.userFirstName && userProfile?.userLastName) {
      return `${userProfile.userFirstName[0]}${userProfile.userLastName[0]}`;
    }
    return '';
  };


  return (
    <div
      className={`offcanvas offcanvas-end pt-5 ${offcanvasOpen ? 'show' : ''}`}
      tabIndex="-1"
      style={{ visibility: offcanvasOpen ? 'visible' : 'hidden' }}
    >
      <div className="offcanvas-header">
        <div className="d-flex align-items-center">
        {userProfile?.profileImageURL ? (
                            <img
                              src={userProfile.profileImageURL}
                              alt="User Avatar"
                              className="rounded-full w-10 h-10 mr-2"
                            />
                          ) : (
                            <div className="bg-indigo-500 rounded-full w-10 h-10 flex items-center justify-center text-white text-xl mr-2">
                              {getUserInitials()}
                            </div>
                          )}
          <div className="ml-2">
            <p className="mb-0">{userProfile?.userFirstName}</p>
            <p className="mb-0">{userProfile?.userLastName}</p>
          </div>
        </div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={toggleOffcanvas}
        ></button>
      </div>
      <div className="offcanvas-body">
        <ul className="nav flex-column">
          <li className="nav-item flex hover:bg-gray-200 rounded px-3">
          <HomeIcon className=' h-6 mt-2 text-gray-600   '/>
            <Link to="/" className="nav-link group flex items-center px-2 py-2 text-base font-medium text-gray-600 rounded-md  hover:text-gray-600 " onClick={toggleOffcanvas}>
           Home
            </Link>
          </li>
         
          <li className="nav-item flex hover:bg-gray-200 rounded px-3">
          <UserIcon className=' h-6 mt-2 text-gray-600  group-hover:text-white '/>
            <Link to="/profile" className="nav-link group flex items-center px-2 py-2 text-base font-medium text-gray-600 rounded-md  hover:text-gray-600 " onClick={toggleOffcanvas}>
              Profile
            </Link>
          </li>
          <li className="nav-item flex hover:bg-gray-200 rounded px-3">
          <AiOutlineSafetyCertificate className='text-2xl h-6 mt-2 text-gray-600  group-hover:text-white '/>
            <Link to="/change-status" className=" nav-link group flex items-center px-2 py-2 text-base font-medium text-gray-600 rounded-md  hover:text-gray-600 " onClick={toggleOffcanvas}>
              Change Stautus
            </Link>
          </li>
         
         
        </ul>
        <li className="nav-item flex hover:bg-gray-200 rounded px-3">
          <AiOutlineFileText className='text-2xl h-6 mt-2 text-gray-600  group-hover:text-white '/>
            <Link to="/terms-privacy" className=" nav-link group flex items-center px-2 py-2 text-base font-medium text-gray-600 rounded-md  hover:text-gray-600 " onClick={toggleOffcanvas}>
              Terms & Privacy
            </Link>
          </li>
        <li className="nav-item border-t border-indigo-700 mt-5 p-4">
            <button
              className="nav-link group flex items-center w-full px-2 py-2 text-base font-medium text-gray-600 rounded-md hover:gray-600 hover:text-gray-600 "
              onClick={handleSignOutClick}
            >
              Sign Out
            </button>
          </li>
      </div>
      
    </div>
  );
};

export default OffcanvasMenu;
