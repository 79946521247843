import React, { useState, useEffect } from 'react';
import { requestForToken } from '../firebase.js'; // Assumes this is where you request the FCM token
import { fetchUserByEmail, updateUser } from '../Api/apiService.js'; // Assumes this is your API service

const EnableNotificationsButton = ({ currentUser }) => {
  const [isStandalone, setIsStandalone] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [isNotificationSupported, setIsNotificationSupported] = useState(false);
  const [platform, setPlatform] = useState(''); // Will be 'iOS' or 'Android' or 'other'

  useEffect(() => {
    // Check if the app is running in standalone mode
    const checkStandaloneMode = () => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      setIsStandalone(isStandalone);
    };

    // Check if the Notification API is supported
    if (typeof window !== 'undefined' && 'Notification' in window) {
      setIsNotificationSupported(true);
      const permission = window.Notification.permission;
      setNotificationsEnabled(permission === 'granted');
    }

    // Detect the platform (iOS or Android)
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setPlatform('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPlatform('iOS');
    } else {
      setPlatform('other');
    }

    checkStandaloneMode();
  }, []);

  const refreshToken = async () => {
    if (currentUser) {
      try {
        const currentToken = await requestForToken(); // Get the FCM token
        const userProfile = await fetchUserByEmail(currentUser.email); // Fetch user profile by email
        if (userProfile && currentToken) {
          // Update the user with the new token
          await updateUser(userProfile.id, { ...userProfile, fcmtoken: currentToken });
          console.log('FCM token updated for user:', userProfile.email);
        }
      } catch (error) {
        console.error('Error refreshing token or updating user:', error);
      }
    }
  };

  const handleEnableNotifications = async () => {
        const permission2 = await Notification.requestPermission()
    const permission = await window.Notification.requestPermission();
    if (permission === 'granted' || permission2 === 'granted') {
      setNotificationsEnabled(true);
      console.log('Notification permission granted.');

      // Update the FCM token in the user profile
      await refreshToken();

      // Refresh the page after permission change
      window.location.reload();
    } else {
      console.error('Notification permission denied.');
    }
  };

  // Popup content with instructions
  const renderInstructions = () => {
    if (platform === 'iOS') {
      return (
        <div className="popup bg-white p-4 rounded-md shadow-md">
          <h3 className="text-lg font-semibold mb-2">Allow Notifications on iOS</h3>
          <p>
            1. Open your device's Settings.<br />
            2. Scroll down and find this app.<br />
            3. Tap on Notifications and enable "Allow Notifications".
          </p>
        </div>
      );
    } else if (platform === 'Android') {
      return (
        <div className="popup bg-white p-4 rounded-md shadow-md">
          <h3 className="text-lg font-semibold mb-2">Allow Notifications on Android</h3>
          <p>
            1. Open your device's Settings.<br />
            2. Navigate to "Apps & notifications".<br />
            3. Find this app and tap on it.<br />
            4. Tap on Notifications and enable "Allow Notifications".
          </p>
        </div>
      );
    }
    return null;
  };

  // Don't show anything if the Notification API is not supported
  if (!isNotificationSupported) {
    return null;
  }

  // Show instructions only if permission is denied
  if (Notification.permission === 'denied') {
    return renderInstructions();
  }

  // Show the button only if in standalone mode and notifications are not enabled and permission is not denied
  if (isStandalone && !notificationsEnabled && Notification.permission !== 'denied') {
    return (
      <div>
        <button onClick={handleEnableNotifications} className="text-sm text-dark w-100 mb-3">
          <div className=" mx-2 ring-1 ring-gray-900/5 btn bg-blue-600 rounded-pill flex items-center text-white mb-2 text-lg font-semibold">
            Enable Notifications to receive alerts
          </div>
        </button>
      </div>
    );
  }

  return null;
};

export default EnableNotificationsButton;
