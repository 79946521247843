import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import '../sitprep.css'; // Your scoped CSS

const EmergencyContacts = () => {
  const [contactGroups, setContactGroups] = useState([]);

  useEffect(() => {
    const savedContacts = JSON.parse(localStorage.getItem('contactGroups'));
    if (savedContacts) {
      setContactGroups(savedContacts);
    }
  }, []);

  const handleInputChange = (contactId, field, value) => {
    const updatedGroups = contactGroups.map(group => {
      if (group.id === contactId) {
        return { ...group, [field]: value };
      }
      return group;
    });
    setContactGroups(updatedGroups);
    saveToLocalStorage(updatedGroups);
  };

  const createContactElement = (contactId, groupName, contactRole, contactName, contactNum, contactChal, contactEml) => (
    <div className="sitprep-app contact-column p-6 mb-6 bg-white rounded-lg shadow-md" id={`contact_${contactId}`} key={contactId}>
      <button className="text-white bg-red-500 hover:bg-red-600 font-bold py-1 px-3 rounded-full float-right" onClick={() => deleteContact(contactId)}>Remove</button>
      <h1 id="groupName" className="font-bold text-2xl mt-3 pb-3 text-blue-700">{groupName}</h1>
      <h5 className="mb-3">Main Contact's Information</h5>
      <div className="form-group flex items-center mt-3 py-2">
        <span className="material-symbols-rounded mt-2">account_circle</span>
        <div className="flex-grow ml-3">
          <select name={`contactRole${contactId}`} className="w-full p-2 rounded shadow-sm border-gray-300" value={contactRole || ''} onChange={(e) => handleInputChange(contactId, 'contactRole', e.target.value)}>
            <option value="" disabled>Select contact role</option>
            <option value="Communication Relay">Communication Relay</option>
            <option value="Emergency Coordinator">Emergency Coordinator</option>
            <option value="Evacuation Coordinator">Evacuation Coordinator</option>
            <option value="Neighborhood Contact">Neighborhood Contact</option>
            <option value="Pet Care Coordinator">Pet Care Coordinator</option>
            <option value="Primary Care Physician">Primary Care Physician</option>
            <option value="Resource Manager">Resource Manager</option>
            <option value="Reunification Coordinator">Reunification Coordinator</option>
            <option value="Safety Checker">Safety Checker</option>
            <option value="Other">Other</option>
          </select>
          <button type="button" className="text-blue-500 ml-2" data-bs-toggle="modal" data-bs-target="#rolesModal">
            <span className="material-icons">help</span>
          </button>
        </div>
      </div>
      <div className="form-group flex items-center mt-3 py-2">
        <span className="material-symbols-rounded mt-2">person</span>
        <input type="text" name={`contactName${contactId}`} className="w-full ml-3 p-2 rounded border-gray-300" placeholder="Enter contact name" value={contactName || ''} onChange={(e) => handleInputChange(contactId, 'contactName', e.target.value)} />
      </div>
      <div className="form-group flex py-2">
        <span className="material-symbols-rounded mt-2">call</span>
        <input type="text" name={`contactNum${contactId}`} className="w-full ml-3 p-2 rounded border-gray-300" placeholder="Enter phone #" value={contactNum || ''} onChange={(e) => handleInputChange(contactId, 'contactNum', e.target.value)} />
      </div>
      <div className="form-group flex py-2">
        <span className="material-symbols-rounded mt-2">radio</span>
        <input type="text" name={`contactChal${contactId}`} className="w-full ml-3 p-2 rounded border-gray-300" placeholder="(optional) Enter 2-way radio channel" value={contactChal || ''} onChange={(e) => handleInputChange(contactId, 'contactChal', e.target.value)} />
        <button type="button" className="text-blue-500 ml-2" data-bs-toggle="modal" data-bs-target="#radioModal">
          <span className="material-icons">help</span>
        </button>
      </div>
      <div className="form-group flex py-2">
        <span className="material-symbols-rounded mt-2">mail</span>
        <input type="email" name={`contactEml${contactId}`} className="w-full ml-3 p-2 rounded border-gray-300" placeholder="Enter email" value={contactEml || ''} onChange={(e) => handleInputChange(contactId, 'contactEml', e.target.value)} />
      </div>
    </div>
  );

  const addContact = (groupName) => {
    const contactId = contactGroups.length;
    const newContact = {
      id: contactId,
      groupName,
      contactRole: '',
      contactName: '',
      contactNum: '',
      contactChal: '',
      contactEml: ''
    };
    setContactGroups([...contactGroups, newContact]);
    saveToLocalStorage([...contactGroups, newContact]);
  };

  const saveContactGroup = () => {
    const groupName = document.getElementById('contactGroupName').value;
    if (groupName.trim() === "") {
      return;
    }
    addContact(groupName);
    document.getElementById('contactGroupName').value = '';
  };

  const deleteContact = (contactId) => {
    const updatedContacts = contactGroups.filter(contact => contact.id !== contactId);
    setContactGroups(updatedContacts);
    saveToLocalStorage(updatedContacts);
  };

  const saveToLocalStorage = (contacts) => {
    localStorage.setItem('contactGroups', JSON.stringify(contacts));
  };

  const downloadAsPDF = () => {
    const element = document.querySelector('#downloadContacts');
    const elementsToHide = element.querySelectorAll('.element-to-hide');
    elementsToHide.forEach((el) => el.setAttribute('hidden', true));
    const elementsToShow = element.querySelectorAll('.element-to-show');
    elementsToShow.forEach((el) => el.removeAttribute('hidden'));

    const options = {
      margin: [5, 5, 5, 5],
      filename: 'emergency_contacts.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true, scrollY: 0 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    window.html2pdf().set(options).from(element).save();

    window.html2pdf().set({
      pagebreak: { mode: 'avoid-all', after: '#page2el' },
    });
  };

  return (
    <div className="sitprep-app">
      <Navbar />
      
      <div className="container mx-auto mt-5 text-blue-700" id="downloadContacts">
        {/* The rest of your JSX */}
      </div>
    </div>
  );
};

export default EmergencyContacts;
