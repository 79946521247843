import React, { useState, useEffect, useCallback } from 'react';
import {
  fetchAllUserInfo,
  fetchAllGroups,
  fetchGroupById,
  updateGroup,
  updateUser,
  fetchUserByEmail,
} from '../Api/apiService';
import IndividualUser from './IndividualUser.js';
import { useNavigate, Link } from 'react-router-dom';
import MapView from './Group Comps/mapView.js';
import { UserIcon } from '@heroicons/react/20/solid';
import { FaMapMarkedAlt } from 'react-icons/fa';
import ShareGroup from './shareGroup.js';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SubGroup from './SubGroups.js'

const tabs = [
  {
    name: 'Map',
    icon: (
      <div className="flex flex-col items-center">
        <FaMapMarkedAlt size={40} />
        <span className="text-sm font-bold">Map</span>
      </div>
    ),
  },
];


const CircularProgress = ({ progress }) => (
  <div className="d-flex my-1">
    <p className="h5 fw-bolder"><span className='text-sm'>Individuals Checked In</span> | {progress}%</p>
  </div>
);

const GroupPageStatusCardWidget = ({
  groupId,
  stopFetchingData,
  setShowRefreshButton,
  setShowSwipeButton,
  userListData,
  setUserListData,
  showGetStatusInfo,
}) => {
  const [filter, setFilter] = useState('ALL');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedTab, setSelectedTab] = useState('List');
  const [activeTab, setActiveTab] = useState('Individuals'); // State to manage active tab

  const navigate = useNavigate();

  const fetchGroupData = useCallback(async () => {
    try {
      const groupsData = await fetchAllGroups();
      const group = groupsData.find((g) => g.groupId.toString() === groupId);
      setSelectedGroup(group);
    } catch (error) {
      console.error('Error fetching group data:', error);
    }
  }, [groupId]);

  const fetchUserData = useCallback(async () => {
    if (selectedGroup) {
      try {
        const userEmails = selectedGroup.memberEmails;
        const allUserInfo = await fetchAllUserInfo();
        const userData = allUserInfo.filter((user) =>
          userEmails.includes(user.userEmail)
        );
        setUserListData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  }, [selectedGroup, setUserListData]);

  useEffect(() => {
    fetchGroupData();
  }, [fetchGroupData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleFilterClick = (status) => setFilter(status);

  const handleTabSwitch = (tabName) => {
    setSelectedTab(tabName);
    if (tabName === 'Map') {
      navigate(`/status-map/${groupId}`);
    }
  };

  const handleEndRequest = async (groupId) => {
    try {
      const groupData = await fetchGroupById(groupId);
      const updatedGroupData = { ...groupData, alert: 'Inactive' };
      await updateGroup(groupId, updatedGroupData);

      const userEmails = groupData.memberEmails;
      const allUserInfo = await fetchAllUserInfo();
      const userData = allUserInfo.filter((user) =>
        userEmails.includes(user.userEmail)
      );

      const updatePromises = userData.map(async (user) => {
        const newAlertCount = Math.max(user.activeGroupAlertCounts - 1, 0);
        await updateUser(user.id, { ...user, activeGroupAlertCounts: newAlertCount });

        const updatedUser = await fetchUserByEmail(user.userEmail);

        if (updatedUser.activeGroupAlertCounts === 0) {
          await updateUser(user.id, {
            ...updatedUser,
            userStatus: 'NO RESPONSE',
            statusColor: '#99738E',
          });
        }
      });

      await Promise.all(updatePromises);

      const currentUser = localStorage.getItem('currentUserEmail');
      let groupDataLocal = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));
      const targetGroupIndex = groupDataLocal.findIndex(
        (group) => group.groupId.toString() === groupId
      );

      if (targetGroupIndex !== -1) {
        groupDataLocal[targetGroupIndex].alert = 'Inactive';
      }

      localStorage.setItem(`groupData-${currentUser}`, JSON.stringify(groupDataLocal));

      const updatedAllUserInfo = await fetchAllUserInfo();
      const updatedUserData = updatedAllUserInfo.filter((user) =>
        userEmails.includes(user.userEmail)
      );
      setUserListData(updatedUserData);

      stopFetchingData();
      navigate(`/Linked/${groupId}`);
    } catch (error) {
      console.error('Error updating group alert status:', error);
    }
  };

  const membersWithStatus = userListData.filter(
    (user) => user.userStatus !== 'NO RESPONSE'
  ).length;
  const totalMembers = userListData.length;
  const percentage = Math.round((membersWithStatus / totalMembers) * 100);

  if (!selectedGroup) return <div>Loading...</div>;

  return (
    <div>
      <div className="d-flex justify-content-between me-4 mt-3">
        <Link
          className="bg-white mx-3 text-2lg font-bold btn"
          to={`/Linked/${groupId}`}
        >
          <NavigateBeforeIcon /> back
        </Link>
      </div>
      <div className="text-center">
        {selectedTab !== 'Map' && (
          <>
            <h1 className="text-3xl font-bold text-dark">
              {selectedGroup.groupName}
            </h1>
            <p className="text-gray-500 text-xs">{selectedGroup.description}</p>
          </>
        )}
      </div>
      <main className="d-flex justify-content-between rounded-pill  mx-3">
        <ul className="nav nav-pills justify-content-start rounded-pill">
          {tabs.map((tab) => (
            <li className="nav-item" key={tab.name}>
              <button
                className={`rounded-pill mx-2 py-2 px-3 ${
                  selectedTab === tab.name
                    ? 'bg-black text-white fw-bold'
                    : 'text-black'
                }`}
                onClick={() => handleTabSwitch(tab.name)}
              >
                {tab.icon} {/* Render the icon directly */}
              </button>
            </li>
          ))}
        </ul>
        {selectedTab !== 'Map' && (
          <div>
            <ShareGroup groupId={groupId} buttonTextColor="text-black me-2" />
          </div>
        )}
      </main>

      {/* Tab Content */}
      {selectedTab === 'Map' ? (
        <div className="">
          <MapView groupData={selectedGroup} />
        </div>
      ) : (
        <main>
          <div className="d-flex justify-content-center me-2 mb-3">
            <button
              className="btn rounded-pill bg-black text-white"
              onClick={() => handleEndRequest(groupId)}
            >
              End Request
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <CircularProgress progress={percentage} />
          </div>

          {/* Filter Buttons */}
          <section className="mb-3 flex flex-nowrap overflow-x-auto scrollbar-hide">
            {['NO RESPONSE', 'INJURED', 'HELP', 'SAFE', 'ALL'].map((status) => (
              <button
                key={status}
                onClick={() => handleFilterClick(status)}
                className={`badge m-1 mx-2 fw-bolder btn btn-sm rounded-pill ${
                  status === 'NO RESPONSE' ? 'custom-bg-purple' : ''
                } ${status === 'INJURED' ? 'custom-bg-red' : ''} ${
                  status === 'HELP' ? 'custom-bg-yellow' : ''
                } ${status === 'SAFE' ? 'custom-bg-green' : ''} ${
                  status === 'ALL' ? 'custom-bg-all' : ''
                }  ${filter === status ? 'active ' : ''}`}
              >
                {status}{' '}
                <span className="ms-2 ">
                  {
                    userListData.filter(
                      (user) => filter === 'ALL' || user.userStatus === status
                    ).length
                  }
                </span>
              </button>
            ))}
          </section>

          {/* List View Content */}
          <div className="container">
              {/* Tab Navigation */}
              <div className="tabs border-t pt-3 flex space-x-4">
  <button
    className={`relative px-4 py-2 text-sm font-semibold transition-all duration-200 ${
      activeTab === 'Individuals'
        ? 'text-blue-600 border-b-4 border-blue-600 rounded'
        : 'text-gray-600 hover:text-blue-600'
    }`}
    onClick={() => setActiveTab('Individuals')}
  >
    Individuals
  </button>
  <button
    className={`relative px-4 py-2 text-sm font-semibold transition-all duration-200 ${
      activeTab === 'Sub Groups'
        ? 'text-blue-600 border-b-4 border-blue-600 rounded'
        : 'text-gray-600 hover:text-blue-600'
    }`}
    onClick={() => setActiveTab('Sub Groups')}
  >
    Sub Groups
  </button>
</div>



              {/* Tab Content */}
              <section className="scrollable-home ">
                {activeTab === 'Individuals' && (
                  <>
                    <p className="text-secondary font-bold text-lg py-4 px-3">
                      Members
                    </p>
                    <div className="pb-5">
                      {userListData.map(
                        (user, index) =>
                          (filter === 'ALL' || user.userStatus === filter) && (
                            <IndividualUser key={index} userList={[user.userEmail]} />
                          )
                      )}
                    </div>
                  </>
                )}
                {activeTab === 'Sub Groups' && (
                  <>
                    <p className="text-secondary font-bold text-lg py-4 px-3">
                      Sub Groups
                    </p>
                    <div className="pb-5">
                      {selectedGroup.subGroupIDs.map((subgroupId, index) => (
                        <SubGroup key={index} groupId={subgroupId} />
                      ))}
                    </div>
                  </>
                )}
              </section>
            </div>
        </main>
      )}
    </div>
  );
};

export default GroupPageStatusCardWidget;
