// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AppContextProvider } from './AppContext';
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './serviceWorkerRegistration';

function Index() {
  return (
    <React.StrictMode>
      <AppContextProvider>
        {/* Add the class 'disable' or remove it dynamically via routing if needed */}
        <div
          className={`pull-to-refresh`}
        ></div>
        <App />
      </AppContextProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));

// Register service worker
registerServiceWorker();

// Optional: Log performance metrics
reportWebVitals();
