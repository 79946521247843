import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const JoinGroup = () => {
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  // Function to extract groupID from URL
  const extractGroupIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('groupId');
  };

  useEffect(() => {
    // Extract groupId from URL query parameters
    const params = new URLSearchParams(location.search);
    const groupId = params.get('groupId');

    // If groupId is present, store it in localStorage or context for future use
    if (groupId) {
      localStorage.setItem('joingroupId', groupId);
    }
  }, [location]);

  const handleContinueButtonClick = () => {
    // Check if the PWA is installed
    window.location.href = 'https://statusnow.app/';
    
    // if (navigator.standalone) {
    //   // Open the PWA using the custom URL scheme
    //   window.location.href = 'web+statusnow://home';
    // } else {
    //   // Navigate to the home page in the web version
    //   navigate('/home');
    // }
  };

  return (
    <div className="overflow-hidden bg-white py-32">
    <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
        <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Welcome to Status Now</h2>
          <p className="mt-6 text-xl leading-8 text-gray-600">
          Thank you for using our application
          </p>
          <p className="mt-6 text-base leading-7 text-gray-600">
            
          </p>
          <div className="mt-10 flex">
          <button
                className="rounded-md bg-success px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleContinueButtonClick}
              >
                Join your group <span aria-hidden="true">&rarr;</span>
              </button>
          </div>
        </div>
        <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
          <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
            <img
              src="https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"
              alt=""
              className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
            />
          </div>
          <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
            <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
              <img
                src="https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
                alt=""
                className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&h=842&q=80"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
              <img
                src="https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
                alt=""
                className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

            
  );
};

export default JoinGroup;
