import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import GreetingMessage from './GreetingMessage';
import '../App.css';
import ShareApp from './shareApp';
import EnableNotificationsButton from './enableNotificationsButton';


const UserInfo = ({ currentUserEmail }) => {  // Accept currentUserEmail as a prop
  
  const navigate = useNavigate();
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userFirstName, setUserFirstName] = useState('');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserProfile = () => {
      if (currentUserEmail) {  // Check if currentUserEmail is provided
        try {
          const storedUserData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
          if (storedUserData) {
            setUserProfile(storedUserData);
            setUserFirstName(storedUserData.userFirstName);
            setUserData(storedUserData);  // Store user profile data
          } else {
            console.error('No user profile found in localStorage');
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [currentUserEmail]);

  const profile = async () => {
    navigate('/Profile');
  };

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };



  return (
    <>
      {currentUserEmail && (
        <div className='d-flex'>
          <GreetingMessage userFirstName={userFirstName} currentUserEmail={currentUserEmail} />  {/* Pass email correctly */}
          <ShareApp />
        </div>
      )}
     
      {/* Conditional messages for missing phone number and address */}
      <EnableNotificationsButton currentUserEmail={currentUserEmail}/>
      <div className="py-1 justify-content-center mb-4">
        {!userData?.phone && (
          <div className="mx-2 ring-1 ring-gray-900/5 btn rounded-pill flex items-center text-dark mb-2 bg-white">
            <FaPhone className="mr-2" />
            <Link to="/profile" className="text-sm text-dark">
              Add a phone number to your profile
            </Link>
          </div>
        )}
        {!userData?.address && (
          <div className="mx-2 ring-1 ring-gray-900/5 btn bg-white rounded-pill flex items-center text-dark mb-2">
            <FaMapMarkerAlt className="mr-2" />
            <Link to="/profile" className="text-sm text-dark">
              Add an address to your profile
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInfo;
