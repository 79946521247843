import React from 'react';

const GreetingMessage = ({ userFirstName, currentUserEmail }) => {
  return (
    <div className='text-gray-800 d-flex flex-column mt-2 mb-3 flex-grow-1'>
      <div>
        <p className=" fs-5 ms-4"> Hi,
          <span className="ms-2 fw-bold fs-5 ">
            {userFirstName || currentUserEmail}!
          </span>
        </p>
        <p className="text-dark fs-6 ms-4"> Welcome to your safety net to check on others...</p>
      </div>
    </div>
  );
};

export default GreetingMessage;
