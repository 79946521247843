import React from 'react';

import Login from '../components/login';
import APITest from '../components/ApiDemo';




// import Footer from '../components/Footer';

const LandingPage = () => {


  return (
    <>
    <div className='fixed-top d-flex bg-home-login justify-content-center  ' style={{ height: '100vh' }}>
    

      <div className="scrollable-home container pt-5  ">

      <Login/>
      {/* < APITest/> */}
      </div>
      {/* <Footer/> */}
   
    </div>
    </>
    
  );
 
};

export default LandingPage;
