import { register } from 'register-service-worker';



export function registerServiceWorker() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    console.log('Registering service workers...');

    // Register the main service worker
    register(`${process.env.PUBLIC_URL}/service-worker.js`, {
      scope: '/',  // Ensure the correct scope
      ready() {
        console.log('Main Service Worker is active and controlling the app.');
      },
      registered(registration) {
        console.log('Main Service Worker registered successfully.');
      },
      cached() {
        console.log('Main Service Worker cached content for offline use.');
      },
      updatefound() {
        console.log('New version of the main Service Worker found, downloading...');
      },
     
      offline() {
        console.log('No internet connection found. App is running in offline mode.');
      },
      error(error) {
        console.error('Error during main Service Worker registration:', error);
      },
    });

    // Register the Firebase messaging service worker
    register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, {
      scope: '/',  // You can adjust the scope if necessary
      ready() {
        console.log('Firebase Messaging Service Worker is registered.');
      },
      registered(registration) {
        console.log('Firebase Messaging Service Worker registered successfully.');
      },
      cached() {
        console.log('Firebase Messaging Service Worker cached content for offline use.');
      },
      updatefound() {
        console.log('New version of the Firebase Messaging Service Worker found.');
      },
      
      offline() {
        console.log('No internet connection found. Firebase Messaging Service Worker is running in offline mode.');
      },
      error(error) {
        console.error('Error during Firebase Messaging Service Worker registration:', error);
      },
    });
  } else {
    console.log('Service workers are not supported in this environment.');
  }
}